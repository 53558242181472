import React, { Component } from 'react'
import ReactSwipe from 'react-swipe'
import { ReactComponent as ArrowTwo } from '../../svg/arrow-two.svg'

class SwipeCarousel extends Component {
  render() {
    const { images } = this.props
    let reactSwipeEl

    return (
      <div className='swipe-carousel'>
        <ReactSwipe
          className='carousel'
          ref={el => (reactSwipeEl = el)}
        >
          {
            images.map((image, i) => {
              return (
                <div key={image + i}>
                  <img src={image} alt='INDIGO STUFF media' />
                </div>
              )
            })
          }
        </ReactSwipe>
        {
          images.length === 1 ? null :
            <div className='swipe-carousel__controls'>
              <button onClick={() => reactSwipeEl.prev()} className='swipe-carousel__prev button button--white'>
                <ArrowTwo title='Previous' />
              </button>
              <button onClick={() => reactSwipeEl.next()} className='swipe-carousel__next button button--white'>
                <ArrowTwo title='Next' />
              </button>
            </div>
        }
      </div>
    )
  }
}

export default SwipeCarousel
