import React from 'react'
import ItemPage from '../Items/ItemPage'
import { Redirect } from 'react-router-dom'

const Item = ({ items, itemsArray, itemId, addItemToBucket, bucket, locale, drate }) => {
  if (!items) return null

  const item = items[itemId]

  if (!item) return <Redirect to='/' />

  if (item.market) return <Redirect to={`/market/${itemId}`} />

  return (
    <ItemPage
      itemId={itemId}
      item={item}
      addItemToBucket={addItemToBucket}
      bucket={bucket}
      locale={locale}
      drate={drate}
      itemsArray={itemsArray}
    />
  )
}

export default Item
