import React from 'react'
import classnames from 'classnames'

const AccountMenuItem = ({ section, handler, name, value }) => {
  const itemClass = classnames({
    'account-menu__item': true,
    'account-menu__item--selected': section === value
  })

  return (
    <li className={itemClass}>
      <button onClick={handler(value)}>
        {name}
      </button>
    </li>
  )
}

export default AccountMenuItem
