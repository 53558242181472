import React, { Component } from 'react'
import { db } from '../../firebase'
import { convertFBListToArray, getDateWithTimeString } from '../../utils'
import Order from './Order'
import LoaderLight from '../common/LoaderLight'
import LoaderSmall from '../common/LoaderSmall'
import { ordersTextData } from '../../data/orders'
import { orderStatusesData } from '../../data/orderStatuses'

class OrdersList extends Component {
  state = {
    ordersArr: [],
    orders: null,
    order: null,
    orderId: null,
    ordersLoading: true
  }

  componentDidMount() {
    db.onceUserOrders(this.props.userId)
      .then(snapshot => {
        const orders = snapshot.val()
        const ordersArr = convertFBListToArray(snapshot).reverse()
        const onlyOrder = ordersArr.length === 1 ? ordersArr[0].uid : null
        const orderStatus = onlyOrder ? orders[onlyOrder].status === 'unpaid' : null

        this.setState({
          ordersArr: ordersArr,
          orders: orders,
          ordersLoading: false,
          orderId: orderStatus ? onlyOrder : null,
          order: orderStatus ? orders[onlyOrder] : null
        })
      })
      .catch(error => {
        this.setState({
          ordersLoading: false
        })
      })
  }

  render() {
    const { loading, locale, drate } = this.props
    const { ordersArr, order, orderId, ordersLoading } = this.state
    const alternativeBlock = ordersLoading || loading ? <LoaderSmall /> : <div className='container-padding'>{ordersTextData[locale].noOrders}</div>

    return (
      <div className='orders form form__not-centered base-text'>
        {
          (loading || ordersLoading) && <LoaderLight />
        }
        <h2 className='form__header page-header base-header container-padding'>
          {ordersTextData[locale].ordersHeader}
        </h2>
        {
          !ordersArr || !ordersArr.length ? alternativeBlock :
          <div className='orders-table'>
            <div className='orders-table__head font-bold'>
              <div>
                {ordersTextData[locale].date}
              </div>
              <div>
                {ordersTextData[locale].buyer}
              </div>
              <div>
                {ordersTextData[locale].stuff}
              </div>
              <div>
                {ordersTextData[locale].status}
              </div>
            </div>
            {
              ordersArr.map((order, index) => {
                const { uid, created, items, status, firstName, lastName } = order
                const name = `${firstName} ${lastName}`

                return (
                  <div key={uid} className='orders-table__row' onClick={this.selectOrder(uid)}>
                    <div className='font-bold'>
                      {getDateWithTimeString(created)}
                    </div>
                    <div>
                      {name}
                    </div>
                    <div>
                      {
                        items.map((item, index) => {
                          const { uid, name } = item
                          const itemName = name ? name[locale] : uid
                          const addition = index === items.length - 1 ? '' : ', '

                          return (
                            <span key={index}>
                              <span className='font-bold'>
                                {`${itemName}${addition}`}
                              </span>
                            </span>
                          )
                        })
                      }
                    </div>
                    <div>
                      {orderStatusesData[locale][status]}
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
        {
          order ?
            <Order
              order={order}
              orderId={orderId}
              selectOrder={this.selectOrder}
              locale={locale}
              updateOrderInState={this.updateOrderInState}
              drate={drate}
            /> : null
        }
      </div>
    )
  }

  selectOrder = orderId => () => this.setState({ order: this.state.orders[orderId] ? this.state.orders[orderId] : null, orderId: orderId })

  updateOrderInState = (res) => () => {
    const { orderId, data } = res
    const { status } = data
    const { ordersArr } = this.state
    const newOrders = {}

    const newOrdersArr = ordersArr.map((order) => {
      if (order.uid === orderId) {
        if (order.status !== status) order.status = status

        order.updated = +new Date()
      }

      const { uid, ...rest } = order

      newOrders[uid] = {...rest}

      return order
    })

    this.setState({
      orders: newOrders,
      ordersArr: newOrdersArr,
      order: newOrders[orderId]
    })
  }
}

export default OrdersList
