import { db } from './fb'
import { functions } from './fb'

export const onceGetItems = () =>
  db.ref('items').once('value')

// export const onceCollections = () =>
//   db.ref('collections').once('value')

export const onceRates = () =>
  db.ref('rates').once('value')

export const onceUserOrders = (userId) =>
  db.ref(`user-orders/${userId}`).once('value')

export const saveUserData = (userId, updates) =>
  db.ref(`users/${userId}`).set(updates)

export const getUserData = (userId) =>
  db.ref(`users/${userId}`).once('value')

export const createOrder = functions.httpsCallable('createOrder')

export const capturePayment = functions.httpsCallable('capturePayment')
