export const formValidationEN = {
  required: 'Required field',
  wrongEmail: 'Incorrect email format',
  wrongPhone: 'Not correct number',
  wrongPwd: 'Password is too short',
  pwdsDontMatch: 'Passwords don\'t match',
  tooLong: 'Too long',
  saveUID: 'Same UID is used',
  wrongValue: 'Wrong value'
}

export const formValidationRU = {
  required: 'Обязательное поле',
  wrongEmail: 'Неверный формат',
  wrongPhone: 'Некорректный номер',
  wrongPwd: 'Пароль слишком короткий',
  pwdsDontMatch: 'Пароли не совпадают',
  tooLong: 'Слишком длинное',
  saveUID: 'Совпадает UID',
  wrongValue: 'Неверное значение'
}

export const formValidationTextData = {
  en: formValidationEN,
  ru: formValidationRU
}
