import React from 'react'
import { NavLink } from 'react-router-dom'
import { makePriceString } from '../../utils'

const BucketTable = ({ bucket, items, addItemToBucket, removeItemFromBucket, checkoutUrl, locale, orderConfirm, drate }) => {
  const itemsToRender = checkoutUrl && orderConfirm ? orderConfirm.items : bucket

  return (
    <div className='bucket-table'>
      {
        itemsToRender.map((item, index) => {
          const { uid, size, amount } = item
          const itemInfo = items[uid]
          const { category } = items[uid][locale]
          const { name, imageFront, images = [], price, sale } = itemInfo
          const totalItemPrice = price * amount
          const totalSalePrice = sale ? sale * amount : 0
          const itemLink = '/stuff/' + uid

          return (
            <div key={uid + index} className='bucket-table__row base-text'>
              <div className='bucket-table__image'>
                <img src={imageFront || images[0]} width='100' alt={name} />
              </div>
              <div className='bucket-table__name'>
                <NavLink to={itemLink}>
                  <span>
                    <span>
                      {`${category} `}
                    </span>
                    <span className='font-bold uppercase'>
                      {name}
                    </span>
                  </span>
                  <div>
                    ({
                      size === 'onesize' ? 'ONE SIZE' : size
                    })
                  </div>
                </NavLink>
              </div>
              <div className='bucket-table__amount'>
                {
                  checkoutUrl ?
                  <div className='bucket-table__amount-inner bucket-table__amount-inner--disabled'>
                    <div className='bucket-table__amount-number font-bold'>
                      {amount}
                    </div>
                  </div> :
                  <div className='bucket-table__amount-inner'>
                    <button className='bucket-table__amount-button button' onClick={removeItemFromBucket(uid, size)}>
                      -
                    </button>
                    <div className='bucket-table__amount-number'>
                      {amount}
                    </div>
                    <button className='bucket-table__amount-button button' onClick={addItemToBucket(uid, size)}>
                      +
                    </button>
                  </div>
                }
              </div>
              <div className='bucket-table__price'>
                <span className={totalSalePrice ? 'line-through': ''}>
                  {makePriceString(locale, totalItemPrice, drate)}
                </span>
                {
                  !totalSalePrice ? null :
                  <span className='bucket-table__sale'>
                    {makePriceString(locale, totalSalePrice, drate)}
                  </span>
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default BucketTable
