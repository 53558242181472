import React from 'react'
import InputBlock from '../common/InputBlock'
import TextareaBlock from '../common/TextareaBlock'
import { ordersTextData } from '../../data/orders'
import { makePriceString } from '../../utils'

const OrderForm = ({ order, onInputChange, onSubmit, bucketAmountWasChanged, onInputBlur, locale, drate, createOrderError }) => {
  const { deliveryType,
          deliveryCost,
          firstName,
          lastName,
          email,
          phone,
          country,
          city,
          zip,
          street,
          // house,
          // building,
          // construction,
          // flat,
          comment,
          errors } = order

  let deliveryCostString = ''

  if (deliveryType === 'rupost') {
    deliveryCostString = `- ${makePriceString(locale, deliveryCost, drate)}`
  }

  return (
    <form onSubmit={onSubmit} className='form'>

      <div className='form__two-inputs-container'>
        <InputBlock
          errors={errors}
          handler={onInputChange}
          name='firstName'
          value={firstName}
          type='text'
          placeholder={ordersTextData[locale].firstName}
          onInputBlur={onInputBlur}
        />
        <InputBlock
          errors={errors}
          handler={onInputChange}
          name='lastName'
          value={lastName}
          type='text'
          placeholder={ordersTextData[locale].lastName}
          onInputBlur={onInputBlur}
        />
      </div>
      <div className='form__two-inputs-container'>
        <InputBlock
          errors={errors}
          handler={onInputChange}
          name='email'
          value={email}
          type='text'
          placeholder={ordersTextData[locale].email}
          onInputBlur={onInputBlur}
          disabled={true}
        />
        <InputBlock
          errors={errors}
          handler={onInputChange}
          name='phone'
          value={phone}
          type='text'
          placeholder={ordersTextData[locale].phone}
          onInputBlur={onInputBlur}
        />
      </div>
      {
        deliveryType === 'pickup' ? null :
        <div>
          <div className='form__two-inputs-container'>
            <InputBlock
              errors={errors}
              handler={onInputChange}
              name='country'
              value={country}
              type='text'
              placeholder={ordersTextData[locale].country}
              onInputBlur={onInputBlur}
            />
            <InputBlock
              errors={errors}
              handler={onInputChange}
              name='zip'
              value={zip}
              type='text'
              placeholder={ordersTextData[locale].zip}
              onInputBlur={onInputBlur}
            />
          </div>

          <InputBlock
            errors={errors}
            handler={onInputChange}
            name='city'
            value={city}
            type='text'
            placeholder={ordersTextData[locale].city}
            onInputBlur={onInputBlur}
          />
          <InputBlock
            errors={errors}
            handler={onInputChange}
            name='street'
            value={street}
            type='text'
            placeholder={ordersTextData[locale].street}
            onInputBlur={onInputBlur}
          />
          {/*<InputBlock
              errors={errors}
              handler={onInputChange}
              name='house'
              value={house}
              type='text'
              placeholder={ordersTextData[locale].house}
              onInputBlur={onInputBlur}
            />
          <div className='form__two-inputs-container'>

            <InputBlock
              errors={errors}
              handler={onInputChange}
              name='building'
              value={building}
              type='text'
              placeholder={ordersTextData[locale].building}
              onInputBlur={onInputBlur}
            />
          </div>*/}
          {/*<div className='form__two-inputs-container'>
            <InputBlock
              errors={errors}
              handler={onInputChange}
              name='construction'
              value={construction}
              type='text'
              placeholder={ordersTextData[locale].construction}
              onInputBlur={onInputBlur}
            />
            <InputBlock
              errors={errors}
              handler={onInputChange}
              name='flat'
              value={flat}
              type='text'
              placeholder={ordersTextData[locale].flatOffice}
              onInputBlur={onInputBlur}
            />
          </div>*/}

        </div>
      }
      <TextareaBlock
        errors={errors}
        handler={onInputChange}
        name='comment'
        value={comment}
        placeholder={ordersTextData[locale].comment}
        onInputBlur={onInputBlur}
      />

      <div className='form__radio-btns base-text'>
        <div className='input-radio'>
          <input type='radio' id='rupost' value='rupost' onChange={onInputChange('deliveryType')} checked={deliveryType === 'rupost'} />
          <label htmlFor='rupost'>
            <span className='font-bold'>{ordersTextData[locale].ruPost} {deliveryCostString}</span> ({ordersTextData[locale].worldwide})
          </label>
        </div>
        <div className='input-radio'>
          <input type='radio' id='pickup' value='pickup' onChange={onInputChange('deliveryType')} checked={deliveryType === 'pickup'} />
          <label htmlFor='pickup'>
            <span className='font-bold'>{ordersTextData[locale].pickup} - {makePriceString(locale, 0, drate)}</span> ({ordersTextData[locale].russiaSpb})
          </label>
        </div>
      </div>
      {
        bucketAmountWasChanged ?
        <div className='form__info small-text red-text'>
          {ordersTextData[locale].bucketAmountWasChanged}
        </div> : null
      }
      {
        createOrderError ?
        <div className='form__info small-text red-text'>
          {ordersTextData[locale].createOrderError}
        </div> : null
      }
      <div className='form__submit-block'>
        <button className='button' type='submit'>
          {ordersTextData[locale].submitForm}
        </button>
      </div>
    </form>
  )
}

export default OrderForm
