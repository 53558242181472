import React from 'react'

const ReturnRU = () => {
  return (
    <div className='info container-padding'>
      <h2 className='page-header base-header'>
        Обмен и Возврат
      </h2>
      <div className='base-text'>
        <p>
          Вы можете вернуть свою покупку в течение 14 дней с момента получения заказа.
          К возврату принимаются только те вещи, которые вы приобрели за полную стоимость.
          Товар, проданный по акции, на маркете, на распродаже или в рамках аукциона, к возврату не принимается.
          По вопросам возврата пишите нам на почту <a href="mailto:yo@theindigostuff.com"  className='link font-thin'>yo@theindigostuff.com</a>.
        </p>
        <p>
          Возврат товара осуществляется через ваше местное почтовое отделение и оплачивается вами.
        </p>
      </div>
      <h3 className='base-text font-bold'>
        Обмен
      </h3>
      <div className='base-text'>
        <p>
          Если вы хотите обменять товар, пожалуйста, свяжитесь с нами по электронной почте <a href="mailto:yo@theindigostuff.com"  className='link font-thin'>yo@theindigostuff.com</a>.
          Большинство товаров поставляются в одном цвете и одном размере. Вы можете обменять товар на такой же, если по каким-то причинам он вам не подошёл.
        </p>
      </div>
      <h3 className='base-text font-bold'>
        Возмещение
      </h3>
      <div className='base-text'>
        <p>
          Мы стремимся обрабатывать каждый возврат в течение двух рабочих дней, с момента его получения. В случае если товар соответствует всем надлежащим требованиям, мы осуществим возврат денежных средств в течение 24-48 часов.
        </p>
      </div>
    </div>
  )
}

export default ReturnRU
