import React from 'react'
import { Redirect } from 'react-router-dom'
import OrdersList from '../Orders/OrdersList'

const Orders = ({ user, loading, locale, drate }) => {
  if (loading) return null

  if (!user) return <Redirect to='/' />

  return <OrdersList userId={user.uid} loading={loading} drate={drate} locale={locale} />
}

export default Orders
