import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { firebase, auth } from '../../firebase'
import Loader from '../common/Loader'
import validator from 'email-validator'
import { authTextData } from '../../data/auth'
import { convertFBCodeMessage } from '../../utils'
import { formValidationTextData } from '../../data/formValidation'
import InputBlock from '../common/InputBlock'

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      error: null,
      loading: false,
      passwordReset: false,
      errors: {}
    }
  }

  render() {
    const { locale } = this.props
    const { email,
            error,
            loading,
            passwordReset,
            errors } = this.state

    return (
      <div className='form'>
        <h2 className='form__header page-header base-header'>
          {authTextData[locale].passwordRecovery}
        </h2>
        {
          loading && <Loader />
        }
        {
          passwordReset ?
            <div className='base-text'>
              {authTextData[locale].checkEmail}
            </div> :
            <form onSubmit={this.onSubmit}>
              <InputBlock
                errors={errors}
                handler={this.onInputChange}
                name='email'
                value={email}
                type='text'
                placeholder='Email'
              />
              { error && <div className='form__error-block red-text small-text'>{error}</div> }
              <div className='form__submit-block form__submit-block--login'>
                <NavLink to='/login' className='link base-text'>
                  {authTextData[locale].backToLogin}
                </NavLink>
                <button className='button' type='submit'>
                  {authTextData[locale].resetPassword}
                </button>
              </div>
            </form>
        }
      </div>
    )
  }

  onInputChange = element => e => {
    this.setState({
      [element]: e.target.value
    })
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.validate()
  }

  validate = () => {
    const { locale } = this.props
    const { email } = this.state
    const errors = {}

    if (!email) errors.email = formValidationTextData[locale].required
    if (email && !validator.validate(email)) errors.email = formValidationTextData[locale].wrongEmail

    if (Object.keys(errors).length) {
      this.setState({ errors })
    } else {
      this.setState({
        errors: errors,
        loading: true,
      }, this.resetPwd)
    }
  }

  resetPwd = () => {
    const { locale } = this.props
    const { email } = this.state

    firebase.auth.languageCode = locale

    auth.doPasswordReset(email)
      .then(() => {
        this.setState({
          loading: false,
          passwordReset: true
        })
      })
      .catch(error => {
        this.setState({ error: convertFBCodeMessage(error, locale), loading: false })
      })
  }
}

export default ForgotPasswordForm
