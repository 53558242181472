import React, { Component } from 'react'
import firebase from 'firebase/app'
import { accountTextData } from '../../data/account'
import Loader from '../common/Loader'
import InputBlock from '../common/InputBlock'
import validator from 'email-validator'
import { formValidationTextData } from '../../data/formValidation'
import { convertFBCodeMessage } from '../../utils'

class ChangeEmailForm extends Component {
  state = {
    password: '',
    newEmail: '',
    error: null,
    loading: false,
    emailChanged: false,
    errors: {}
  }

  render() {
    const { locale, email } = this.props
    const { password,
            newEmail,
            error,
            loading,
            emailChanged,
            errors } = this.state

    return (
      <div className='form form__not-centered'>
        <h2 className='form__header base-header page-header'>
          {accountTextData[locale].changeEmail}
        </h2>
        {
          loading && <Loader />
        }
        {
          emailChanged ?
            <div className='base-text'>
              {accountTextData[locale].emailChanged}
            </div> :
            <form onSubmit={this.onSubmit}>
              <InputBlock
                handler={this.onInputChange}
                name='password'
                value={password}
                type='password'
                placeholder={accountTextData[locale].currentPwd}
                errors={errors}
              />

              <InputBlock
                handler={this.onInputChange}
                name='currentEmail'
                value={email}
                type='text'
                placeholder={accountTextData[locale].currentEmail}
                errors={errors}
                disabled={true}
              />

              <InputBlock
                handler={this.onInputChange}
                name='newEmail'
                value={newEmail}
                type='text'
                placeholder={accountTextData[locale].newEmail}
                errors={errors}
              />
              { error ? <div className='form__error'>{error}</div> : null }
              <div className='form__submit-block'>
                <button className='button' type='submit'>
                  {accountTextData[locale].changeEmailSubmit}
                </button>
              </div>
            </form>
        }
      </div>
    )
  }

  onInputChange = element => e => {
    this.setState({
      [element]: e.target.value
    })
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.validate()
  }

  validate = () => {
    const { locale } = this.props
    const { password, newEmail } = this.state
    const errors = {}

    if (!newEmail) errors.newEmail = formValidationTextData[locale].required
    if (newEmail && !validator.validate(newEmail)) errors.newEmail = formValidationTextData[locale].wrongEmail

    if (!password) errors.password = formValidationTextData[locale].required
    if (password && password.length < 6) errors.password = formValidationTextData[locale].wrongPwd

    if (Object.keys(errors).length) {
      this.setState({ errors })
    } else {
      this.setState({
        errors: errors,
        loading: true,
      }, this.changeEmail)
    }
  }

  reauthenticate = (currentPassword) => {
    const user = firebase.auth().currentUser
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword)
    return user.reauthenticateAndRetrieveDataWithCredential(credential)
  }

  changeEmail = () => {
    const { locale } = this.props
    const { newEmail, password } = this.state
    const user = firebase.auth().currentUser

    firebase.auth.languageCode = locale

    this.reauthenticate(password)
      .then(() => {
        user.updateEmail(newEmail)
          .then(() => {
            // TODO: test all variants

            user.sendEmailVerification()
              .then(() => {
                this.setState({
                  emailChanged: true,
                  loading: false
                })
              }).catch((error) => {
                this.setState({
                  error: convertFBCodeMessage(error, locale),
                  loading: false
                })
              })

            this.setState({
              loading: false,
              emailChanged: true
            })
          })
          .catch((error) => { this.setState({ error: convertFBCodeMessage(error, locale), loading: false }) })
      })
      .catch((error) => { this.setState({ error: convertFBCodeMessage(error, locale), loading: false }) })
  }
}

export default ChangeEmailForm
