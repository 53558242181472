import React, { Component } from 'react'
import Loader from '../common/Loader'
import { firebase, auth } from '../../firebase'
import validator from 'email-validator'
import InputBlock from '../common/InputBlock'
import { convertFBCodeMessage } from '../../utils'
import { checkoutTextData } from '../../data/checkout'
import { authTextData } from '../../data/auth'
import { formValidationTextData } from '../../data/formValidation'

class SignUpForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      passwordOne: '',
      passwordTwo: '',
      loading: false,
      errors: {},
      error: null
    }
  }

  render() {
    const { locale } = this.props
    const { email,
            passwordOne,
            passwordTwo,
            loading,
            error,
            errors } = this.state

    return (
      <form onSubmit={this.onSubmit} className='form'>
        {
          loading && <Loader />
        }
        <h2 className='form__header page-header base-header'>
          {checkoutTextData[locale].signUpHeader}
        </h2>
        <InputBlock
          errors={errors}
          handler={this.onInputChange}
          name='email'
          value={email}
          type='text'
          placeholder={authTextData[locale].email}
        />
        <InputBlock
          errors={errors}
          handler={this.onInputChange}
          name='passwordOne'
          value={passwordOne}
          type='password'
          placeholder={authTextData[locale].password}
        />
        <InputBlock
          errors={errors}
          handler={this.onInputChange}
          name='passwordTwo'
          value={passwordTwo}
          type='password'
          placeholder={authTextData[locale].confirmPassword}
        />
        { error ? <div className='form__error'>{error}</div> : null }
        <div className='form__submit-block'>
          <button className='button' type='submit'>
            {
              loading ? authTextData[locale].loading : authTextData[locale].signUpSubmit
            }
          </button>
        </div>
      </form>
    )
  }

  onInputChange = element => e => {
    this.setState({
      [element]: e.target.value
    })
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.validate()
  }

  validate = () => {
    const { locale } = this.props
    const { email, passwordOne, passwordTwo } = this.state
    const errors = {}
    if (!email) errors.email = formValidationTextData[locale].required
    if (email && !validator.validate(email)) errors.email = formValidationTextData[locale].wrongEmail

    if (!passwordOne) errors.passwordOne = formValidationTextData[locale].required
    if (passwordOne && passwordOne.length < 6) errors.passwordOne = formValidationTextData[locale].wrongPwd

    if (passwordOne !== passwordTwo) errors.passwordTwo = formValidationTextData[locale].pwdsNotMatch

    if (Object.keys(errors).length) {
      this.setState({ errors })
    } else if (email && passwordOne) {
      this.setState({
        errors: errors,
        loading: true,
      }, this.doSignUp)
    }
  }

  doSignUp = () => {
    const { locale } = this.props
    const { email,
            passwordOne } = this.state

    auth.doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        firebase.auth.languageCode = locale
        firebase.auth.currentUser.sendEmailVerification()
      })
      .catch(error => {
        this.setState({
          error: convertFBCodeMessage(error, locale),
          loading: false })
      })
  }
}

export default SignUpForm
