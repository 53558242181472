export const emailVerifyEN = {
  header: 'Email Verification',
  verified: 'Verified!',
  link: 'Go to Home page',
  error: 'Error. Please contact us about this problem.'
}

export const emailVerifyRU = {
  header: 'Подтверждение Почтового Ящика',
  verified: 'Подтверждён!',
  link: 'Перейти на главную страницу',
  error: 'Ошибка. Пожалуйста, свяжитесь с нами по поводу этой проблемы.'
}

export const emailVerifyTextData = {
  en: emailVerifyEN,
  ru: emailVerifyRU
}
