import React from 'react'
import DeliveryRU from '../common/DeliveryRU'
import DeliveryEN from '../common/DeliveryEN'

const DeliveryAndPayment = ({ locale }) => {
  if (locale === 'en') return <DeliveryEN />
  return <DeliveryRU />
}

export default DeliveryAndPayment
