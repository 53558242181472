import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { createAddressString, getDateWithTimeString, getDeliveryType, calculateOrderTotal } from '../../utils'
import { ordersTextData } from '../../data/orders'
import { orderStatusesData } from '../../data/orderStatuses'
import { capturePayment } from '../../firebase/db'
import { ReactComponent as CrossIcon } from '../../svg/cross-black.svg'
import { makePriceString } from '../../utils'

class Order extends Component {
  state = {
    loading: false,
    error: null,
    pending: false
  }

  componentDidMount() {
    const { order, orderId, updateOrderInState } = this.props
    const { status, yandex } = order

    // console.log(order)
    // console.log('----')

    if (status === 'unpaid' && yandex && yandex.payment) {
      this.setState({ loading: true })

      capturePayment({ payment: yandex.payment, orderId: orderId })
        .then((res) => {
          // const paymentStatus = res.data ? res.data.status : ''

          const paymentStatus = res.data ? res.data.paymentStatus : ''

          // console.log(res.data)
          // console.log('-----')

          if (paymentStatus === 'pending') {
            this.setState({
              pending: true,
              loading: false,
              error: null
            })
          } else if (paymentStatus === 'paid' || paymentStatus === 'canceled') {
            res.orderId = orderId

            this.setState({
              loading: false,
              error: null
            }, updateOrderInState(res))
          } else {
            this.setState({
              loading: false,
              error: null
            })
          }
        })
        .catch((error) => {
          // TODO: handle
          this.setState({
            loading: false,
            error: null
          })
        })
    }
  }

  render() {
    const { loading, pending } = this.state
    const { order, selectOrder, locale, drate } = this.props
    const { address,
            delivery,
            created,
            status,
            items,
            tracking,
            email,
            phone,
            firstName,
            lastName,
            yandex,
            comment } = order
    const { type, cost } = delivery
    const pickupDelivery = type === 'pickup'

    const totalPrice = calculateOrderTotal(items) + cost

    const totalString = makePriceString(locale, totalPrice, drate)
    // const totalString = locale === 'en' && pending ? `$${Math.ceil(totalPrice / 100 / drate)}` : `${totalPrice / 100} ₽`

    const trackingLink = locale === 'en' ? `https://www.pochta.ru/en/tracking#${tracking}` : `https://www.pochta.ru/tracking#${tracking}`

    const trackingElement = type === 'pickup' || status === 'unpaid' || status === 'canceled' ? null :
      <div className='form__info'>
        <div className='form__info-label font-bold'>
          {ordersTextData[locale].tracking}:
        </div>
        <div className='form__info-content'>
          {
            tracking ? <a href={trackingLink} className='link' target='_blank' rel='noopener noreferrer'>{tracking}</a> : '-'
          }
          {
            tracking && status === 'sent' ? <div className='form__warning form__warning--small secondary-text'>{ordersTextData[locale].checkTrackingNote}</div> : null
          }
        </div>
      </div>

    const paymentNoteElement = locale === 'en' && pending ?
      <div className='form__warning'>
        Currently we are working only with Russian Rubles. Site currency rate is: <span className='form__warning-currency font-bold'>$</span>1 = {drate} RUB. Attention! The internal exchange rate of site may be different from the rate of the issuing bank of your card. You will be redirected to Yandex.Money service to make a payment via debit/credit card.
      </div> : null

    const statusElement = pending ?
      <span>
        <span>{orderStatusesData[locale][status]}</span>
        <a href={yandex.checkout} className='order__payment-link button'>
          {`${orderStatusesData[locale].pay} ${totalString}`}
        </a>
      </span>
       : orderStatusesData[locale][status]

    return (
      <div className='order form base-text' onClick={loading ? null : selectOrder(null)}>
        <div className='order__inner' onClick={this.windowClick}>
          <div>
            <div className='order__header'>
              <h2 className='page-header page-header--no-top-pading base-header'>
                {ordersTextData[locale].orderHeader}
              </h2>
              <button onClick={loading ? null : selectOrder(null)}>
                <CrossIcon title={ordersTextData[locale].close} />
              </button>
            </div>
            <div className='form__info'>
              <div className='form__info-label font-bold'>
                {ordersTextData[locale].date}:
              </div>
              <div className='form__info-content'>
                {getDateWithTimeString(created)}
              </div>
            </div>
            <div className='form__info'>
              <div className='form__info-label font-bold'>
                {ordersTextData[locale].status}:
              </div>
              <div className='form__info-content'>
                <div>{loading ? orderStatusesData[locale].loading : statusElement}</div>
                {
                  status === 'paid' ? <div className='form__warning form__warning--small secondary-text'>{ordersTextData[locale].willBePacked}</div> : null
                }
                {
                  status === 'packed' ?
                    <div className='form__warning form__warning--small secondary-text'>
                      {pickupDelivery ? ordersTextData[locale].takeFromShowRoom : ordersTextData[locale].willBeSent}
                    </div>
                    : null
                }
              </div>
            </div>
            {paymentNoteElement}
            {trackingElement}
            <div className='form__info'>
              <div className='form__info-label font-bold'>
                {ordersTextData[locale].buyer}:
              </div>
              <div className='form__info-content'>
                <span>
                  {`${firstName} ${lastName}`}
                </span>
                <span className='one-line-string'>
                  {email}
                </span>
                <span>
                  +{phone}
                </span>
              </div>
            </div>
            <div className='form__info'>
              <div className='form__info-label font-bold'>
                {ordersTextData[locale].address}:
              </div>
              <div className='form__info-content'>
                <span>
                  {createAddressString(address, locale)}
                </span>
              </div>
            </div>
            <div className='form__info'>
              <span className='form__info-label font-bold'>
                {ordersTextData[locale].delivery}:
              </span>
              <span className='form__info-content'>
                <span>{getDeliveryType(type, locale)}</span>
                <span>
                  {makePriceString(locale, cost, drate)}
                </span>
              </span>
            </div>
            <div className='form__info'>
              <span className='form__info-label font-bold'>
                {ordersTextData[locale].payment}:
              </span>
              <span className='form__info-content'>
                {ordersTextData[locale].card}
              </span>
            </div>
            <div className='form__info'>
              <span className='form__info-label font-bold'>
                {ordersTextData[locale].total}:
              </span>
              <span className='form__info-content'>
                {totalString}
              </span>
            </div>
            {
              comment ?
              <div className='form__info'>
                <span className='form__info-label font-bold'>
                  {ordersTextData[locale].comment}:
                </span>
                <span className='form__info-content'>
                  {comment}
                </span>
              </div> : null
            }
            <div className='form__info-label font-bold'>
              {ordersTextData[locale].stuff}:
            </div>
            <div className='form__info-content'>
              <div>
                {
                  items.map((item, index) => {
                    const { uid, size, amount, price, name, imageFront, preorder } = item
                    const itemName = name ? name[locale] : uid
                    const totalItemPrice = price * amount
                    let preorderElement

                    if (preorder && (status === 'unpaid' || status === 'paid' || status === 'packed')) {
                      preorderElement = <span>{preorder[locale]}</span>
                    }

                    return (
                      <div key={uid + index} className='form__info-content-item form__info-content-item--order-item'>
                        <div>
                          <NavLink to={`/stuff/${uid}`}>
                            <img src={imageFront} width='100' alt={`${itemName}`} />
                          </NavLink>
                        </div>
                        <span className='font-bold'>{amount} x {itemName} – {size} – {makePriceString(locale, totalItemPrice, drate)}</span>
                        {preorderElement}
                      </div>
                    )

                    // return (
                    //   <div key={uid + index} className='form__info-content-item'>
                    //     <div>
                    //       <span>{amount} x </span> <span className='font-bold'>{` ${itemName}`}</span> ({ size === 'onesize' ? 'ONE SIZE' : size }) {preorderElement}
                    //     </div>
                    //     <div className=''>
                    //       {makePriceString(locale, totalItemPrice, drate)}
                    //     </div>
                    //   </div>
                    // )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  windowClick = event => event.stopPropagation()
}

export default Order
