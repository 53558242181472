export const pwdResetEN = {
  header: 'Password Reset',
  verified: 'Password changed.',
  link: 'To home page',
  error: 'Error. Try again.',
  submit: 'Set new password',
  newPwd: 'New Password'
}

export const pwdResetRU = {
  header: 'Сброс пароля',
  verified: 'Пароль изменён.',
  link: 'На главную страницу',
  error: 'Ошибка. Попробуй ещё раз.',
  submit: 'Установить новый пароль',
  newPwd: 'Новый Пароль'
}

export const pwdResetTextData = {
  en: pwdResetEN,
  ru: pwdResetRU
}
