import React from 'react'

function SecondBanner() {
  return (
    <div className='items-container__banner items-container__banner--homeless'>
        <div className='items-container__banner-image--portrait'>
          <img src='https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/2024%2Fbanners%2Findigo-black-friday-banner-2-mobile.png?alt=media&token=a3ff13e9-9e09-481a-8b34-177a68ab798b' width='250' alt='INDIGO STUFF' />
        </div>
        <div className='items-container__banner-image--landscape'>
          <img src='https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/2024%2Fbanners%2Findigo-black-friday-banner-2-desk.png?alt=media&token=564369df-b820-418b-b15e-fc92a633932a' width='250' alt='INDIGO STUFF' />
        </div>
    </div>
  )
}

export default SecondBanner
