import React from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
// import UnderConstruction from './components/common/UnderConstruction'

// import registerServiceWorker from './registerServiceWorker'


// ReactDOM.render(<UnderConstruction />, document.getElementById('root'))

ReactDOM.render(<Root />, document.getElementById('root'))
// registerServiceWorker()


function unregister() {
  if (typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for (let registration of registrations) {
        console.log('unregister')
        registration.unregister()
      }
    })
  }
}

unregister();
