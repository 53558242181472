import React from 'react'
import { createAddressString, getDeliveryType } from '../../utils'
import { ordersTextData } from '../../data/orders'
import { makePriceString } from '../../utils'

const OrderConfirm = ({ order, total, locale, checkoutUrl, drate }) => {
  const { firstName,
          lastName,
          email,
          phone,
          delivery,
          address } = order
  const { type,
          cost } = delivery

  const delCost = type === 'pickup' ? 0 : cost
  const totalPrice = total + delCost

  return (
    <div className='base-text'>
      <div className='form__info'>
        <div className='form__info-label font-bold'>
          {ordersTextData[locale].buyer}:
        </div>
        <div className='form__info-content'>
          <span className='one-line-string'>
            {firstName + ' ' + lastName}
          </span>
          <span className='one-line-string'>
            {email}
          </span>
          <span className='one-line-string'>
            +{phone}
          </span>
        </div>
      </div>
      <div className='form__info'>
        <span className='form__info-label font-bold'>
          {ordersTextData[locale].delivery}:
        </span>
        <span className='form__info-content'>
          <span>{getDeliveryType(type, locale)}</span>
          <span>{makePriceString(locale, delCost, drate)}</span>
        </span>
      </div>
      <div className='form__info'>
        <div className='form__info-label font-bold'>
          {ordersTextData[locale].address}:
        </div>
        <div className='form__info-content'>
          <span>
            {createAddressString(address, locale)}
          </span>
        </div>
      </div>
      <div className='form__info'>
        <span className='form__info-label font-bold'>
          {ordersTextData[locale].payment}:
        </span>
        <span className='form__info-content'>
          {ordersTextData[locale].card}
        </span>
      </div>
      <div className='form__info'>
        <span className='form__info-label font-bold'>
          {ordersTextData[locale].total}:
        </span>
        <span className='form__info-content'>
          {makePriceString(locale, totalPrice, drate)}
        </span>
      </div>
      <div className='form__warning form__warning--checkout'>
        {
          locale === 'ru' ?
          <div>
            Для оплаты вы будете перенаправлены на сайт сервиса ЮMoney. Если платёж с вашей карты не проходит, пишите нам на <a href='mailto:yo@theindigostuff.com' className='link font-thin'>
              yo@theindigostuff.com
            </a> и мы сможем предоставить вам ссылку на PayPal, где вы сможете оплатить заказ через PayPal, PayPal Credit или любой кредитной/дебетовой картой.
          </div> :
          <div>
            Currently we are working only with Russian Rubles. Site currency rate is: <span className='font-bold'>$</span>1 = {drate} RUB. Attention! The internal exchange rate of site may be different from the rate of the issuing bank of your card. You will be redirected to YooMoney service to make a payment via debit/credit card. If you have a problem with payment just write us on <a href='mailto:yo@theindigostuff.com'  className='link font-thin'>
              yo@theindigostuff.com
            </a> and we will provide a secure payment link via PayPal. You will be able to pay using a credit or debit card, PayPal account, or PayPal Credit.
          </div>
        }
      </div>
      <div className='form__submit-block'>
        <a href={checkoutUrl} className='button'>
          {ordersTextData[locale].checkout}
        </a>
      </div>
    </div>
  )
}

export default OrderConfirm
