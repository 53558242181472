import React from 'react'
import ItemsList from '../Items/ItemsList'
import { sortItems } from '../../utils'

const Market = ({ items, locale, drate, hofmannita }) => {
  if (!items || !items.length) return null

  const filteredItems = sortItems(items.filter(item => item.market && !item.hide && item.collection !== 'garage-sale-hofmannita'))

  return (
    <div className='stuff'>
      <ItemsList
        items={filteredItems}
        market={true}
        locale={locale}
        drate={drate}
        moreStuffRoute={'/hofmannita'}
        project='market'
      />
    </div>
  )
}

export default Market
