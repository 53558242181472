import React from 'react'
import BucketTable from '../Orders/BucketTable'
import AuthBlock from '../Orders/AuthBlock'
import OrderMain from '../Orders/OrderMain'
import { authTextData } from '../../data/auth'

const Checkout = ({ user, bucket, items, addItemToBucket, removeItemFromBucket, bucketAmountWasChanged, createOrder, loading, locale, checkoutUrl, orderConfirm, drate, createOrderError }) => {
  if (!bucket.length && !orderConfirm) {
    return (
      <div className='checkout-page checkout-page--empty base-text'>
        <div className='checkout-page__cart'>
          <h2 className='base-header page-header'>
            {authTextData[locale].cart}
          </h2>
          <div>
            {
              bucketAmountWasChanged ?
                authTextData[locale].emptyChangedBucket :
                authTextData[locale].emptyBucket
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='checkout-page'>
      <div className='checkout-page__cart'>
        <h2 className='base-header page-header'>
          {authTextData[locale].cart}
        </h2>
        <BucketTable
          bucket={bucket}
          items={items}
          addItemToBucket={addItemToBucket}
          removeItemFromBucket={removeItemFromBucket}
          locale={locale}
          checkoutUrl={checkoutUrl}
          orderConfirm={orderConfirm}
          drate={drate}
        />
      </div>
      <div className='checkout-page__ordering'>
        <h2 className='base-header page-header'>
          {authTextData[locale].ordering}
        </h2>
        {
          !user ?
            <AuthBlock locale={locale} noRegWarning={true} /> :
            <OrderMain
              user={user}
              bucketAmountWasChanged={bucketAmountWasChanged}
              createOrderError={createOrderError}
              createOrder={createOrder}
              bucket={bucket}
              items={items}
              locale={locale}
              checkoutUrl={checkoutUrl}
              orderConfirm={orderConfirm}
              drate={drate}
            />
        }
      </div>
    </div>
  )
}

export default Checkout
