import React from 'react'
import ForgotPasswordForm from '../Account/ForgotPasswordForm'
import { Redirect } from 'react-router-dom'

const Account = ({ user, locale }) => {
  if (user) return <Redirect to='/' />

  return (
    <div className='login container-padding'>
      <ForgotPasswordForm user={user} locale={locale}/>
    </div>
  )
}

export default Account
