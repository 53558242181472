import React from 'react'
import { NavLink } from 'react-router-dom'
import { itemTextData } from '../../data/item'
import classnames from 'classnames'
// import LazyLoad from 'react-lazyload'
import { makePriceString } from '../../utils'

const ItemsListComponent = ({ filteredItems, locale, changeLayout, drate }) => {
  return (
    <div className='items-list'>
      {
        filteredItems.map((stuff, i) => {
          const { uid, images = [], imageFront, imageBack, name, price, sale, amount, market } = stuff
          const { category, preorder } = stuff[locale]
          let soldOut = true
          let label = null
          let labelClass = 'items-list__label'

          // item will be available soon
          const soonItem = price === 100

          if (market) labelClass += ' items-list__label--market'

          for (const size in amount) {
            if (amount[size]) soldOut = false
          }

          if (soldOut) {
            labelClass += ' items-list__label--sold-out'
            label = <span className={labelClass}>{itemTextData[locale].notAvailable}</span>
          }
          // else if (sale) {
          //   labelClass += ' items-list__label--sale'
          //   label = <span className={labelClass}>Sale</span>
          // }
          else if (preorder) {
            labelClass += ' items-list__label--preorder'
            label = <span className={labelClass}>{itemTextData[locale].preorder}</span>
          } else if (soonItem) {
            labelClass += ' items-list__label--preorder'
            label = <span className={labelClass}>{itemTextData[locale].soon}</span>
          }

          const priceClass = classnames({
            'items-list__price small-text': true,
            'line-through': sale,
            'items-list__price--soon': soonItem
          })

          const mainImageClass = classnames({
            'items-list__main-image': true,
            'items-list__main-image--one': images.length === 1
          })

          const itemLink = market ? `/market/${uid}` : `/stuff/${uid}`

          const namePartOne = locale === 'ru' ? <span className='font-thin'>{category}</span> : <span className='font-bold uppercase'>{name}</span>
          const namePartTwo = locale === 'ru' ? <span className='font-bold uppercase'>{name}</span> : <span className='font-thin'>{category}</span>

          const imageOne = imageFront || images[0]
          let imageTwo

          if (imageBack || images.length > 1) {
            imageTwo = imageBack || images[images.length - 1]
          }

          const itemClass = classnames({
            'items-list__item': true,
            'items-list__item--small': changeLayout
          })

          return (
                <div key={`${uid}_${i}`} className={itemClass}>
                  <NavLink  to={itemLink} className='items-list__link'>
                    <div className='items-list__image'>
                      <img className={mainImageClass} src={imageOne} loading="lazy" width='250' alt={`${name} ${category}`} />
                      {
                        imageTwo ? <img className='items-list__second-image' loading="lazy" src={imageTwo} width='250' alt={`${name} ${category}`} /> : null
                      }
                      {label}
                    </div>
                    <div className='items-list__info'>
                      <h2 className='items-list__name small-text'>
                        <div>
                          {namePartOne} {namePartTwo}
                        </div>
                      </h2>
                      <div>
                        <span className={priceClass}>
                          {makePriceString(locale, price, drate)}
                        </span>
                        {
                          sale ?
                              <span className='items-list__sale small-text'>
                                {makePriceString(locale, sale, drate)}
                              </span> : null
                        }
                      </div>
                    </div>
                  </NavLink>
                </div>
          )
        })
      }
    </div>
  )
}

export default ItemsListComponent
