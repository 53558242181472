import React, { Component } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import classnames from 'classnames'
import Header from './common/Header'
import Footer from './common/Footer'
// import Loader from './common/Loader'
import HeartLoader from './common/HeartLoader'

// import Ticker from './common/Ticker'
// import Home from './routes/Home'
// import Collection from './routes/Collection'
import Item from './routes/Item'
import ItemMarket from './routes/ItemMarket'
import Stuff from './routes/Stuff'
import Market from './routes/Market'
import PM from './routes/PM'
// import Hofmannita from './routes/Hofmannita'
// import Emoland from './routes/Emoland'
// import Paris2023 from './routes/Paris2023'
import Sale from './routes/Sale'
// import Psychea from './routes/Psychea'
import Login from './routes/Login'
import Account from './routes/Account'
import ForgotPassword from './routes/ForgotPassword'
import Checkout from './routes/Checkout'
import Contacts from './routes/Contacts'
import DeliveryAndPayment from './routes/DeliveryAndPayment'
import ReturnAndExchange from './routes/ReturnAndExchange'
import Orders from './routes/Orders'
import CustomEmailHandler from './routes/CustomEmailHandler'
import { auth, db, firebase } from '../firebase'
import { createOrder } from '../firebase/db'
import { convertFBListToArray,
         saveBucketToLocalStorage,
         getBucketFromLocalStorage,
         clearBucketAndOrderInLocalStorage,
         checkAndSaveUserUpdates,
         getLocale,
         setLocale } from '../utils'
import './style.js'
// import { collections } from '../data/collections'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: null,
      dataLoading: true,
      userLoading: true,
      error: null,
      items: null,
      // collections: null,
      itemsArray: null,
      // collectionsArray: null,
      bucket: [],
      bucketAmountWasChanged: false,
      createOrderError: false,
      locale: null,
      checkoutUrl: '',
      orderConfirm: null,
      drate: 0
    }
  }

  componentWillMount() {
    this.setState({
      locale: getLocale()
    })
  }

  componentDidMount() {
    firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState({ user: authUser, userLoading: false, dataLoading: true }, this.loadRates)
        : this.setState({ user: null, userLoading: false, dataLoading: true }, this.loadRates)
    })
  }

  // loadCollections = () => {
  //   if (!this.state.dataLoading) this.setState({ dataLoading: true })
  //
  //   db.onceCollections()
  //     .then(snapshot => this.setState({
  //       collections: snapshot.val(),
  //       collectionsArray: convertFBListToArray(snapshot),
  //       // TODO: handle errors
  //       // error: null
  //     }, this.loadRates))
  // }

  loadRates = () => {
    if (!this.state.dataLoading) this.setState({ dataLoading: true })

    db.onceRates()
      .then(snapshot => this.setState({
        drate: snapshot.val()['dol'],
        // TODO: handle errors
        // error: null
      }, this.loadItems))
  }

  loadItems = () => {
    db.onceGetItems()
      .then(snapshot =>
        this.setState({
          items: snapshot.val(),
          itemsArray: convertFBListToArray(snapshot),
          bucket: getBucketFromLocalStorage(),
          // TODO: handle errors
          error: null
        }, this.loadUserData))
  }

  loadUserData = () => {
    if (this.state.user) {
      const user = {...this.state.user}

      db.getUserData(user.uid)
        .then(snapshot => {
          const userWithData = {...user, ...snapshot.val()}

          this.setState({
            user: userWithData,
            // TODO: handle errors
            error: null
          }, this.checkBucketAmount)
        })
    } else {
      this.checkBucketAmount()
    }
  }

  render() {
    const { history } = this.props
    const { user,
            dataLoading,
            userLoading,
            bucket,
            itemsArray,
            items,
            bucketAmountWasChanged,
            locale,
            checkoutUrl,
            // collections,
            // collectionsArray,
            orderConfirm,
            drate,
            createOrderError } = this.state
    const loading = dataLoading || userLoading
    const homePage = history.location.pathname === '/'
    const contactsPage = history.location.pathname === '/contacts'
    const accountPage = history.location.pathname === '/account'
    const loginPage = history.location.pathname === '/login'
    const checkoutPage = history.location.pathname === '/checkout'
    // const paris23Page = history.location.pathname === '/paris2023'
    const recoverPwd = history.location.pathname === '/forgot-password'
    // const legalPage = history.location.pathname === '/delivery-and-payment'

    const mainClass = classnames({
      'app__main': true,
      // 'app__main--indigobg': contactsPage || loginPage || recoverPwd || legalPage
      // 'container': !homePage,
      'app__main--not-home-page': !homePage,
      'app__main--home-page': homePage
    })

    return (
      <div className='app'>
        <Header
          user={user}
          bucketAmount={bucket.length}
          locale={locale}
          changeLocale={this.changeLocale}
        />
        <main className={mainClass}>
          { loading && <HeartLoader /> }
            <Switch>
              {/*<Route exact path='/paris2023' render={() =>
                <Paris2023 /> }
              />*/}

              <Route exact path='/' render={() =>
                <Stuff items={itemsArray} locale={locale} drate={drate} /> }
              />

              {/*<Route exact path='/stuff' render={() =>
                <Stuff items={itemsArray} locale={locale} drate={drate} /> }
              />*/}

              <Route exact path='/market' render={() =>
                <Market items={itemsArray} locale={locale} drate={drate} />}
              />

              <Route exact path='/poshlaya-molly' render={() =>
                <PM items={itemsArray} locale={locale} drate={drate} />}
              />

              {/*<Route exact path='/sale' render={() =>
                <Sale items={itemsArray} locale={locale} drate={drate} />}
              />*/}

              <Route exact path='/stuff/:itemId' render={({ match }) =>
                <Item
                  key={match.params.itemId}
                  items={items}
                  itemsArray={itemsArray}
                  itemId={match.params.itemId}
                  addItemToBucket={this.addItemToBucket}
                  bucket={bucket}
                  locale={locale}
                  drate={drate}
                /> }
              />

              <Route exact path='/market/:itemId' render={({ match }) =>
                <ItemMarket
                  key={`market_${match.params.itemId}`}
                  items={items}
                  itemsArray={itemsArray}
                  itemId={match.params.itemId}
                  addItemToBucket={this.addItemToBucket}
                  bucket={bucket}
                  locale={locale}
                  drate={drate}
                /> }
              />

              <Route exact path='/account' render={() =>
                <Account
                  user={user}
                  signOut={this.signOut}
                  loading={loading}
                  locale={locale}
                /> }
              />

              <Route exact path='/login' render={() => <Login user={user} locale={locale} checkoutPage={checkoutPage} /> } />

              <Route exact path='/forgot-password' render={() =>
                <ForgotPassword user={user} locale={locale} /> }
              />

              <Route exact path='/checkout' render={() =>
                <Checkout
                  user={user}
                  bucket={bucket}
                  items={items}
                  addItemToBucket={this.addItemToBucket}
                  removeItemFromBucket={this.removeItemFromBucket}
                  bucketAmountWasChanged={bucketAmountWasChanged}
                  createOrderError={createOrderError}
                  createOrder={this.createOrder}
                  loading={loading}
                  locale={locale}
                  checkoutUrl={checkoutUrl}
                  orderConfirm={orderConfirm}
                  drate={drate}
                /> }
              />

              <Route exact path='/orders' render={() =>
                <Orders
                  user={user}
                  loading={loading}
                  locale={locale}
                  drate={drate}
                /> }
              />

              <Route exact path='/contacts' render={() => <Contacts locale={locale} /> } />
              <Route exact path='/delivery-and-payment' render={() => <DeliveryAndPayment locale={locale} /> } />
              <Route exact path='/return-and-exchange' render={() => <ReturnAndExchange locale={locale} /> } />

              <Route path='/user-action' render={({ location }) =>
                <CustomEmailHandler
                  location={location}
                  locale={locale}
                /> }
              />

              <Redirect to='/' />
            </Switch>
        </main>
        {
          loading || contactsPage || accountPage || loginPage || recoverPwd ? null :
          <Footer changeLocale={this.changeLocale} locale={locale} />
        }
      </div>
    )
  }

  checkBucketAmount = () => {
    const bucket = this.state.bucket.slice(0)
    const { items } = this.state

    if (!bucket.length) {
      this.setState({ dataLoading: false })
    } else {
      let bucketAmountWasChanged = false

      const newBucket = bucket.map((itemInBucket) => {
        const { uid, amount, size } = itemInBucket

        const amountInStore = items[uid].amount[size]

        if (amount > amountInStore) {
          itemInBucket.amount = amountInStore
          bucketAmountWasChanged = true
        }

        return itemInBucket
      })

      const filteredBucket = newBucket.filter(item => item.amount > 0)

      this.setState({
        bucket: filteredBucket,
        bucketAmountWasChanged: bucketAmountWasChanged,
        dataLoading: false
      }, saveBucketToLocalStorage(filteredBucket))
    }
  }

  addItemToBucket = (uid, sizeSelected, type) => () => {
    const { orderConfirm, checkoutUrl } = this.state
    const bucket = this.state.bucket.slice(0)
    const index = bucket.findIndex(item => item.uid === uid && item.size === sizeSelected)

    if (bucket[index]) {
      if (bucket[index].amount >= 10) return;

      bucket[index].amount = bucket[index].amount + 1
    } else {
      bucket.push({
        uid: uid,
        size: sizeSelected,
        amount: 1,
        type: type
      })
    }

    if (orderConfirm || checkoutUrl) {
      this.setState({
        checkoutUrl: '',
        orderConfirm: null,
        bucket: bucket,
        bucketAmountWasChanged: false,
      }, saveBucketToLocalStorage(bucket))
    } else {
      this.setState({
        bucket: bucket,
        bucketAmountWasChanged: false,
      }, saveBucketToLocalStorage(bucket))
    }
  }

  removeItemFromBucket = (uid, sizeSelected) => () => {
    const bucket = this.state.bucket.slice(0)
    const index = bucket.findIndex(item => item.uid === uid && item.size === sizeSelected)

    if (bucket[index]) {
      bucket[index].amount = bucket[index].amount - 1

      if (bucket[index].amount < 1) {
        bucket.splice(index, 1)
      }

      this.setState({
        bucket: bucket,
        bucketAmountWasChanged: false,
      }, saveBucketToLocalStorage(bucket))
    }
  }

  createOrder = order => {
    const { locale } = this.state

    this.setState({ dataLoading: true, createOrderError: false })

    const data = {
      order: order,
      locale: locale
    }

    createOrder(data)
      .then((res) => {
        clearBucketAndOrderInLocalStorage()

        this.setState({
          checkoutUrl: res.data.url,
          // dataLoading: false,
          bucket: [],
          orderConfirm: order
        }, this.checkUserProfile(order))
      })
      .catch((error) => {
        // TODO: handle
        // show just common message to reload page, repeat order?
        console.log(error)
        console.log(error.message)
        this.setState({
          dataLoading: false,
          createOrderError: true
        })
      })
  }

  checkUserProfile = order => {
    window.scrollTo(0, 0)

    const { user } = this.state
    const updates = checkAndSaveUserUpdates(user, order)
    const self = this

    if (!Object.keys(updates).length) {
      self.setState({
        dataLoading: false
      })
    } else {
      // TODO: check displayName and use it in Account, in Emails, ...
      db.saveUserData(user.uid, updates)
        .then(function() {
          self.setState({
            dataLoading: false
          })
        })
        .catch(function(error) {
          // TODO: handle error
          console.log(error)
          console.log(error.message)
        })
    }
  }

  signOut = () => {
    this.setState({ userLoading: true })

    auth.doSignOut()
      .then(() => {
        clearBucketAndOrderInLocalStorage()

        this.setState({
          user: null,
          userLoading: false,
          bucketAmountWasChanged: false,
          checkoutUrl: '',
          bucket: []
        })
      })
      .catch((error) => {
        this.setState({ userError: error, userLoading: false })
      })
  }

  changeLocale = locale => () => this.setState({ locale }, setLocale(locale))

  updateItemsInState = () => {
    db.onceGetItems()
      .then(snapshot =>
        this.setState({
          items: snapshot.val(),
          itemsArray: convertFBListToArray(snapshot),
          dataLoading: false
        }))
  }
}

export default withRouter(App)
