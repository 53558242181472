import React from 'react'
import classnames from 'classnames'

const TextareaBlock = ({ label, errors, handler, name, value, type, placeholder, onInputBlur, disabled }) => {
  const errorClass = classnames({
    'form__error-field': true,
    'form__error-field--visible': errors[name]
  })

  const inputClass = classnames({
    'input-text': true,
    'input-text--error': errors[name]
  })

  const placeholderClass = classnames({
    'form__placeholder': true,
    'form__placeholder--moved': value,
    'form__placeholder--left-padding': !value && name === 'phone'
  })

  const inputBlockClass = classnames({
    'form__input': true,
    'form__input--phone': name === 'phone'
  })

  return (
    <div className='form__input-block'>
      {
        label ?
        <div className='form__label base-text'>
          <label htmlFor={name}>
            {label}
          </label>
        </div> : null
      }
      <div className={inputBlockClass}>
        <textarea
          id={name}
          value={value}
          onChange={handler(name)}
          className={inputClass}
          onBlur={onInputBlur}
          disabled={disabled}
        />
        <span className={errorClass}>
          {errors[name]}
        </span>
      </div>
      {
        placeholder ?
          <span className={placeholderClass}>
            {placeholder}
          </span> : null
      }
    </div>
  )
}

export default TextareaBlock
