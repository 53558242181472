import React from 'react'
import ReturnRU from '../common/ReturnRU'
import ReturnEN from '../common/ReturnEN'

const ReturnAndExchange = ({ locale }) => {
  if (locale === 'en') return <ReturnEN />
  return <ReturnRU />
}

export default ReturnAndExchange
