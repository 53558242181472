export const headerEN = {
  home: 'HOME',
  sale: 'SALE',
  stuff: 'STUFF',
  indigoStuff: 'INDIGO STUFF',
  market: 'MARKET',
  emoland: 'EMOLAND',
  pm: 'RATT\'N\'WHORE',
  psychea: 'PSYCHEA',
  newStuff: 'NEW STUFF',
  contacts: 'CONTACTS',
  orders: 'ORDERS',
  account: 'ACCOUNT',
  login: 'LOGIN'
}

export const headerRU = {
  home: 'ГЛАВНАЯ',
  sale: 'SALE',
  stuff: 'СТАФФ',
  indigoStuff: 'INDIGO STUFF',
  market: 'МАРКЕТ',
  emoland: 'EMOLAND',
  pm: 'RATT\'N\'WHORE',
  psychea: 'ПСИХЕЯ',
  newStuff: 'НОВЫЙ СТАФФ',
  contacts: 'КОНТАКТЫ',
  orders: 'ЗАКАЗЫ',
  account: 'АККАУНТ',
  login: 'ВХОД'
}

export const headerTextData = {
  en: headerEN,
  ru: headerRU
}
