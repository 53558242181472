export const ordersEN = {
  orderHeader: 'Order',
  ordersHeader: 'Orders',
  noOrders: 'No orders.',
  date: 'Date',
  buyer: 'Buyer',
  stuff: 'Stuff',
  status: 'Status',
  ruPost: 'Russian Post',
  pickup: 'Pickup',
  worldwide: 'Worldwide shipping',
  russiaSpb: 'Russia, Saint Petersburg',
  tracking: 'Tracking Number',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  country: 'Country',
  zip: 'ZIP',
  city: 'City',
  street: 'Address Line',
  house: 'House',
  building: 'Building',
  construction: 'Construction',
  flatOffice: 'Apartment',
  comment: 'Comment',
  bucketAmountWasChanged: 'Some items were sold already and amount was changed. Check cart again, please.',
  createOrderError: 'Sorry! Something went wrong. Try again please. If a problem will not disappear please contact us about it.',
  submitForm: 'Create Order',
  delivery: 'Delivery',
  address: 'Address',
  payment: 'Payment',
  card: 'Debit/Credit card',
  total: 'Total',
  close: 'Close',
  checkout: 'Checkout',
  // warning: 'Warning! This site is currently working in TEST MODE. There will be no any money transfers after clicking on "Checkout", only order will be created. When testing will be finished all users and orders will be deleted.',
  willBePacked: 'Order will be packed soon.',
  willBeSent: 'Order will be sent soon.',
  takeFromShowRoom: 'Order packed. You can take it from showroom. Check CONTACTS page for more information.',
  checkTrackingNote: 'Click on number to get info about package.',
  report: 'Report'
}

export const ordersRU = {
  orderHeader: 'Заказ',
  ordersHeader: 'Заказы',
  noOrders: 'Нет заказов.',
  date: 'Дата',
  buyer: 'Покупатель',
  stuff: 'Стафф',
  status: 'Статус',
  ruPost: 'Почта России',
  pickup: 'Самовывоз',
  worldwide: 'Доставка по всему миру',
  russiaSpb: 'Россия, Санкт-Петербург',
  tracking: 'Трекинг Номер',
  firstName: 'Имя',
  lastName: 'Фамилия',
  email: 'Email',
  phone: 'Телефон',
  country: 'Страна',
  zip: 'Индекс',
  city: 'Город',
  street: 'Улица, дом, квартира',
  house: 'Дом',
  building: 'Корпус',
  construction: 'Строение',
  flatOffice: 'Квартира',
  comment: 'Комментарий',
  bucketAmountWasChanged: 'Некоторые вещи уже проданы и их количество изменено. Проверь корзину ещё раз, пожалуйста.',
  createOrderError: 'Извините! Что-то пошло не так. Попробуйте ещё раз. Если проблема не исчезнет, пожалуйста, сообщите нам об этом.',
  submitForm: 'Создать Заказ',
  delivery: 'Доставка',
  address: 'Адрес',
  payment: 'Оплата',
  card: 'Дебет./Кредит. карта',
  total: 'Итого',
  close: 'Закрыть',
  checkout: 'Оплатить',
  // warning: 'Внимание! В данный момент сайт работает в ТЕСТОВОМ РЕЖИМЕ. Трансфер денег не будет происходить, после того, как вы нажмёте "Оплатить", будет лишь создан заказ. После завершения тестирования все пользователи и заказы будут удалены.',
  willBePacked: 'Заказ собирается.',
  willBeSent: 'Заказ скоро будет отправлен.',
  takeFromShowRoom: 'Заказ собран. Вы можете забрать его из нашего шоурума. Проверьте страницу КОНТАКТЫ для большей информации.',
  checkTrackingNote: 'Нажмите на номер, чтобы получить информацию о посылке.',
  report: 'Отчёт'
}

export const ordersTextData = {
  en: ordersEN,
  ru: ordersRU
}
