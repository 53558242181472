import React from 'react'
import { ReactComponent as GarageBannerMobile } from '../../svg/2023-garage-banner-mobile.svg'
import { ReactComponent as GarageBannerDesktop } from '../../svg/2023-garage-banner-desktop.svg'

function MarketBanner() {
  let classBanner = 'items-container__banner--alt'

  const banner = <GarageBannerMobile title='GARAGE SALE'  />
  const bannerDesk = <GarageBannerDesktop title='GARAGE SALE' />
  classBanner = 'items-container__banner--alt items-container__banner--less-top-padding items-container__banner--garage'

  return (
    <div className={classBanner}>
        <div className='items-container__banner-image--portrait'>
          {banner}
        </div>
        <div>
          <div className='items-container__banner-image--landscape'>
            {bannerDesk}
          </div>
        </div>
    </div>
  )
}

export default MarketBanner
