import React from 'react'
import { NavLink } from 'react-router-dom'
import { contactsTextData } from '../../data/contacts'
// import { ReactComponent as FBLogo } from '../../svg/fb.svg'
import { ReactComponent as IGLogo } from '../../svg/insta.svg'
import { ReactComponent as TikTokLogo } from '../../svg/tiktok.svg'
import { ReactComponent as VKLogo } from '../../svg/vk.svg'

const Contacts = ({ locale }) => {
  const texts = contactsTextData[locale]
  return (
    <div className='contacts container-padding'>
      <h2 className='page-header base-header'>
        {texts.header}
      </h2>
      <div className='contacts__info base-text font-bold'>
        <div className='contacts__item'>
          <div>
            {texts.showroom}:
          </div>
          <a href='https://yandex.ru/maps/-/CCUERGta2B' target='_blank' rel="noopener noreferrer" className='link font-thin'>{contactsTextData[locale].showroomAddress}</a>
          <div className='base-text font-thin grey-text'>
            {texts.workingHours}
          </div>
          <div className='base-text font-thin grey-text'>
            {texts.note}
          </div>
        </div>
        <div className='contacts__item'>
          <div>
            {texts.phone}:
          </div>
          <a href='tel:+79117060245'  className='link font-thin'>
            +7 911 70 60 245
          </a>
          <div className='base-text font-thin grey-text'>
            Telegram, WhatsApp, iMessage, FaceTime, SMS
          </div>
        </div>
        <div className='contacts__item'>
          <div>
            {texts.email}:
          </div>
          <a href='mailto:yo@theindigostuff.com'  className='link font-thin'>
            yo@theindigostuff.com
          </a>
        </div>
        <div className='contacts__item'>
          <div>
            {texts.links}:
          </div>
          <div className='contacts__social-links'>
            <a href='https://www.instagram.com/theindigostuff' className='contacts__social-link social-link' target='_blank' rel='noopener noreferrer'>
              <IGLogo title='Instagram' />
            </a>
            {
              /*
              <a href='https://www.facebook.com/theindigostuff' className='contacts__social-link social-link' target='_blank' rel='noopener noreferrer'>
                <FBLogo title='Facebook' />
              </a>
              */
            }
            <a href='https://vk.com/theindigostuff' className='contacts__social-link social-link' target='_blank' rel='noopener noreferrer'>
              <VKLogo title='VK' />
            </a>
            <a href='https://www.tiktok.com/@theindigostuff' className='contacts__social-link social-link' target='_blank' rel='noopener noreferrer'>
              <TikTokLogo title='TikTok' />
            </a>
          </div>
        </div>
        <div>
          <NavLink to='/delivery-and-payment' className='link'>
            {texts.legal}
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default Contacts
