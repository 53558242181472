export const emailRecoverEN = {
  header: 'Email Recover',
  recovered: 'Account email reverted to previous.',
  link: 'To home page',
  error: 'Error. Try again.'
}

export const emailRecoverRU = {
  header: 'Восстановление Email',
  recovered: 'Аккаунт восстановлен с прежним email.',
  link: 'На главную страницу',
  error: 'Ошибка. Попробуй ещё раз.'
}

export const emailRecoverTextData = {
  en: emailRecoverEN,
  ru: emailRecoverRU
}
