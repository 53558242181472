import React, { Component } from 'react'
import firebase from 'firebase/app'
import { auth } from '../../firebase'
import Loader from '../common/Loader'
import { accountTextData } from '../../data/account'
import InputBlock from '../common/InputBlock'
import { formValidationTextData } from '../../data/formValidation'
import { convertFBCodeMessage } from '../../utils'

class ChangePasswordForm extends Component {
  state = {
    password: '',
    passwordOne: '',
    passwordTwo: '',
    error: null,
    loading: false,
    passwordChanged: false,
    errors: {}
  }

  render() {
    const { locale } = this.props
    const { password,
            passwordOne,
            passwordTwo,
            error,
            loading,
            passwordChanged,
            errors } = this.state

    return (
      <div className='form form__not-centered'>
        <h2 className='form__header base-header page-header'>
          {accountTextData[locale].changePwd}
        </h2>
        {
          loading && <Loader />
        }
        {
          passwordChanged ?
            <div className='base-text'>
              {accountTextData[locale].pwdChanged}
            </div> :
            <form onSubmit={this.onSubmit}>
              <InputBlock
                handler={this.onInputChange}
                name='password'
                value={password}
                type='password'
                placeholder={accountTextData[locale].currentPwd}
                errors={errors}
              />

              <InputBlock
                handler={this.onInputChange}
                name='passwordOne'
                value={passwordOne}
                type='password'
                placeholder={accountTextData[locale].newPwd}
                errors={errors}
              />

              <InputBlock
                handler={this.onInputChange}
                name='passwordTwo'
                value={passwordTwo}
                type='password'
                placeholder={accountTextData[locale].confirmNewPwd}
                errors={errors}
              />
              { error ? <div className='form__error'>{error}</div> : null }
              <div className='form__submit-block'>
                <button className='button' type='submit'>
                  {accountTextData[locale].changePwdSubmit}
                </button>
              </div>
            </form>
        }
      </div>
    )
  }

  onInputChange = element => e => {
    this.setState({
      [element]: e.target.value
    })
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.validate()
  }

  validate = () => {
    const { locale } = this.props
    const { password, passwordOne, passwordTwo } = this.state
    const errors = {}

    if (!password) errors.password = formValidationTextData[locale].required
    if (password && password.length < 6) errors.password = formValidationTextData[locale].wrongPwd
    if (!passwordOne) errors.passwordOne = formValidationTextData[locale].required
    if (passwordOne && passwordOne.length < 6) errors.passwordOne = formValidationTextData[locale].wrongPwd
    if (passwordOne !== passwordTwo) errors.passwordTwo = formValidationTextData[locale].pwdsDontMatch

    if (Object.keys(errors).length) {
      this.setState({ errors })
    } else {
      this.setState({
        errors: errors,
        loading: true,
      }, this.changePassword)
    }
  }

  reauthenticate = (currentPassword) => {
    const user = firebase.auth().currentUser
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword)
    return user.reauthenticateAndRetrieveDataWithCredential(credential)
  }

  changePassword = () => {
    const { locale } = this.props
    const { password, passwordOne } = this.state

    this.reauthenticate(password)
      .then(() => {
        auth.doPasswordUpdate(passwordOne)
          .then(() => {
            this.setState({
              loading: false,
              passwordChanged: true
            })
          })
          .catch((error) => {
            console.log(error)
            this.setState({ error: convertFBCodeMessage(error, locale), loading: false })
          })
      })
      .catch((error) => {
        console.log(error)
        this.setState({ error: convertFBCodeMessage(error, locale), loading: false }) })
  }
}

export default ChangePasswordForm
