import React from 'react'

const DeliveryEN = () => {
  return (
    <div className='info container-padding'>
      <h2 className='page-header base-header'>
        Delivery and Payment
      </h2>
      <h3 className='base-text font-bold'>
        Legal Entity
      </h3>
      <div className='base-text'>
        <p>
          Chirkova Liubov Sergeevna <br/>
          195027, Russia, Saint Petersburg, Energetikov avenue, 9 c6
        </p>
      </div>
      <h3 className='base-text font-bold'>
        Privacy
      </h3>
      <div className='base-text'>
        <p>
          Any personal information you forward to theindigostuff.com will only be used in order to fulfill the undertakings theindigostuff.com have and to assure you the best service. theindigostuff.com will not let a third party see or use your personal details without your prior consent. You have the right to see your personal details upon request. If the information is incomplete or incorrect, you have the right to have the information corrected or deleted.
        </p>
        <p>
          As soon as we have received your order, you will receive an automatic e-mail confirming your order. We reserve the right not to deliver if the stock level was incorrect at the time of receiving the order. Should we be unable to deliver in accordance with the terms stated in the confirmation of the order, we will contact you and give you the opportunity to cancel the order. Due to the color settings of your computer, the colors of the products on the pictures in the theindigostuff.com can differ from their actual colors. theindigostuff.com cannot be held responsible for any such discrepancy.
        </p>
      </div>
      <h3 className='base-text font-bold'>
        Delivery
      </h3>
      <div className='base-text'>
        <p>
          In most cases, if your order was placed prior to 3pm, Monday to Friday, we are able to ship it the very same day. Otherwise your order will be sent out on the next business day.
        </p>
      </div>
      <h3 className='base-text font-bold'>
        Collect from showroom
      </h3>
      <div className='base-text'>
        <p>
          To pick up your order from our store in Saint Petersburg, please select 'Pickup' option at the checkout.
        </p>
      </div>
      <h3 className='base-text font-bold'>
        Payment
      </h3>
      <div className='base-text'>
        <p>
          theindigostuff.com accepts payment by VISA and Mastercard. The full payment is charged upon placing order. All transactions are encrypted and 100% secure. theindigostuff.com reserves the right to hold all orders for two business days to ensure a safe payment transaction.
        </p>
        <p>
          Sometimes banks may use additional security layer for online credit and debit card transactions called 3-D Secure. In this case, you will be redirected to the website of the card issuing bank to authorize the transaction with one-time confirmation code provided by your bank via SMS, confirmation codes card or security device.
        </p>
      </div>
      <h3 className='base-text font-bold'>
        Currency
      </h3>
      <div className='base-text'>
        <p>
          All payments to theindigostuff.com are made in russian rubles. theindigostuff.com is not responsible for any fluctuations in currency exchange rates or currency exchange rates provided by your bank when converting to Rubles.
        </p>
      </div>
    </div>
  )
}

export default DeliveryEN
