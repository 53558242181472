import React, { Component } from 'react'
import { Redirect, NavLink } from 'react-router-dom'
import ChangePasswordForm from '../Account/ChangePasswordForm'
import ChangeEmailForm from '../Account/ChangeEmailForm'
import AccountMenu from '../Account/AccountMenu'
import { accountTextData } from '../../data/account'
import firebase from 'firebase/app'

class Account extends Component {
  state = {
    section: 'Account',
    success: false,
    error: false,
    loadingInner: false
  }

  render() {
    const { signOut, user, loading, locale } = this.props
    const { success, error, loadingInner } = this.state

    if (loading) {
      return null
    } else if (!user) {
      return <Redirect to='/' />
    }

    const { email, displayName, emailVerified } = user
    const { section } = this.state

    let content = null

    switch (section) {
      case 'Change Email':
        content = <ChangeEmailForm locale={locale} email={email} />
        break
      case 'Change Password':
        content = <ChangePasswordForm locale={locale} />
        break
      default:
        let element = emailVerified ? null : <div style={{marginBottom: '10px'}}><button onClick={this.sendVerificationEmail}className='button'>{accountTextData[locale].sendVerificationEmail}</button></div>

        if (success) element = <div style={{marginBottom: '10px'}}>{accountTextData[locale].sendVerifySuccess}</div>
        if (error) element = <div style={{marginBottom: '10px'}}>{accountTextData[locale].sendVerifyError}</div>
        if (loadingInner) element = <div style={{marginBottom: '10px'}}>{accountTextData[locale].sendVerifyLoading}</div>

        content =
          <div className='form'>
            <h2 className='form__header base-header page-header'>
              {accountTextData[locale].account}
            </h2>
            <div className='form__input-block'>
              {
                displayName || null
              }
            </div>
            <div className='form__input-block'>
              {email}
              {
                emailVerified ?
                null :
                // <div className='green-text'>({accountTextData[locale].verified})</div> :
                <div className='red-text'>({accountTextData[locale].notVerified})</div>
              }
            </div>
            <div className='form__input-block'>
              {element}
              <button onClick={signOut} className='button button--red'>
                {accountTextData[locale].signOut}
              </button>
            </div>
            <div style={{marginTop: '50px' }}>
              <NavLink to='/orders' className='link'>
                {accountTextData[locale].orders}
              </NavLink>
            </div>
          </div>
    }

    return (
      <div className='account-page'>
        <div className='account-page__content'>
          {content}
        </div>
        <div className='account-page__menu'>
          <AccountMenu section={section} selectSection={this.selectSection} signOut={signOut} locale={locale} />
        </div>
      </div>
    )
  }

  selectSection = (section) => () => this.setState({ section })

  sendVerificationEmail = () => {
    this.setState({ loadingInner: true }, this.doSendVerificationEmail)
  }

  doSendVerificationEmail = () => {
    const { locale } = this.props
    const user = firebase.auth().currentUser

    firebase.auth().languageCode = locale

    user.sendEmailVerification()
      .then(() => {
        this.setState({
          success: true,
          error: false,
          loadingInner: false
        })
      }).catch((error) => {
        this.setState({
          error: true,
          success: false,
          loadingInner: false
        })
      })
  }
}

export default Account
