export const footerEN = {
  delivery: 'Delivery and Payment',
  return: 'Return and Exchange',
  contacts: 'Contacts'
}

export const footerRU = {
  delivery: 'Доставка и Оплата',
  return: 'Возврат и Обмен',
  contacts: 'Контакты'
}

export const footerTextData = {
  en: footerEN,
  ru: footerRU
}
