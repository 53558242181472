export const checkoutEN = {
  cart: 'Cart',
  ordering: 'Ordering',
  signUp: 'register',
  login: 'login',
  signUpHeader: 'Registration',
  loginHeader: 'Login',
  emptyBucket: 'SHOPPING CART IS EMPTY.',
  emptyChangedBucket: 'Sorry, items are sold out :(',
  please: 'Please',
  or: 'or'
}

export const checkoutRU = {
  cart: 'Корзина',
  ordering: 'Заказ',
  signUp: 'зарегистрируйтесь',
  login: 'войдите',
  signUpHeader: 'Регистрация',
  loginHeader: 'Вход',
  emptyBucket: 'КОРЗИНА ПУСТА.',
  emptyChangedBucket: 'Извини, нет в наличии :(',
  please: 'Пожалуйста',
  or: 'или'
}

export const checkoutTextData = {
  en: checkoutEN,
  ru: checkoutRU
}
