import validator from 'email-validator'
import { ordersTextData } from '../data/orders'
import { formValidationTextData } from '../data/formValidation'
import { fbErrorsTextData } from '../data/fbErrors'

export function getLocale() {
  let locale = localStorage.getItem('indigostore-locale')
  let lastUpdate = localStorage.getItem('indigo-expire')
  let now = new Date()
  let hours = 0

  if (!lastUpdate) {
    localStorage.setItem('indigo-expire', `${new Date()}`)
  } else {
    const lastUpdateDate = new Date(lastUpdate)
    hours = Math.abs(now - lastUpdateDate) / 36e5

    if (hours > 2) {
      localStorage.removeItem('indigo-bucket')
      localStorage.removeItem('indigo-order')
    }
  }

  if (!locale || (locale !== 'en' && locale !== 'ru')) {

    locale = window.navigator ? (window.navigator.language ||
             window.navigator.systemLanguage ||
             window.navigator.userLanguage) : ''

    locale = locale.substr(0, 2).toLowerCase()

    if (locale !== 'ru' && locale !== 'en') locale = 'en'
  }

  setLocale(locale)

  return locale
}

export function setLocale(locale) {
  if (!locale || (locale !== 'en' && locale !== 'ru')) locale = 'ru'
  localStorage.setItem('indigostore-locale', locale)
}

export function saveBucketToLocalStorage(bucket) {
  localStorage.setItem('indigo-bucket', JSON.stringify(bucket))
}

export function getBucketFromLocalStorage() {
  const JSONbucket = localStorage.getItem('indigo-bucket')

  if (JSONbucket) {
    const bucket = JSON.parse(JSONbucket)
    const filteredBucket = bucket.filter(item => item.amount > 0)
    return filteredBucket
  } else {
    return []
  }
}

export function clearBucketAndOrderInLocalStorage() {
  localStorage.removeItem('indigo-bucket')
  localStorage.removeItem('indigo-order')
}

export function saveOrderToLocalStorage(order) {
  localStorage.setItem('indigo-order', JSON.stringify(order))
}

export function getOrderFromLocalStorage() {
  const order = localStorage.getItem('indigo-order')
  return order ? JSON.parse(order) : null
}

export function getDateWithTimeString(ISOString) {
  const date = new Date(ISOString)

  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hours = date.getHours()
  let minutes = date.getMinutes()

  if (day < 10) day = '0' + day
  if (month < 10) month = '0' +  month
  if (hours < 10) hours = '0' + hours
  if (minutes < 10) minutes = '0' +  minutes

  return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes
}

export function getDateString(ISOString) {
  const date = new Date(ISOString)

  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()

  if (day < 10) day = '0' + day
  if (month < 10) month = '0' +  month

  return day + '.' + month + '.' + year
}

export function getDateStringValid(d) {
  const date = new Date(d)

  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()

  if (day < 10) day = '0' + day
  if (month < 10) month = '0' +  month

  return `${year}-${month}-${day}`
}

export function convertFBListToArray(list) {
  if (!list) return null

  const data = []

  list.forEach(s => {
    data.push({
      uid: s.key,
      ...s.val()
    })
  })

  return data
}

export function convertFBCodeMessage(error, locale) {
  // console.log(error)
  switch (error.code) {
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return fbErrorsTextData[locale].wrongEmailPwd
    case 'auth/email-already-in-use':
      return fbErrorsTextData[locale].emailInUse
    default:
      return error.message
  }
}

export function createAddressString({country, city, zip, street, house, building, construction, flat }, locale) {
  const string = `${zip}, ${country}, ${city}, ${street}`
  return string
}

export function validateOrderForm(order, locale) {
  const errors = {}
  const { firstName,
          lastName,
          country,
          city,
          zip,
          street,
          comment,
          email,
          phone } = order

  if (!firstName) {
    errors.firstName = formValidationTextData[locale].required
  } else if (typeof firstName !== 'string' || firstName.length > 100) {
    errors.firstName = formValidationTextData[locale].tooLong
  }

  if (!lastName) {
    errors.lastName = formValidationTextData[locale].required
  } else if (typeof lastName !== 'string' || lastName.length > 100) {
    errors.lastName = formValidationTextData[locale].tooLong
  }

  if (!email) {
    errors.email = formValidationTextData[locale].required
  } else if (!validator.validate(email)) {
    errors.email = formValidationTextData[locale].wrongEmail
  }

  if (!phone) {
    errors.phone = formValidationTextData[locale].required
  } else if (!Number.isInteger(+phone)) {
    errors.phone = formValidationTextData[locale].wrongPhone
  }

  if (!zip) {
    errors.zip = formValidationTextData[locale].required
  } else if (typeof zip !== 'string' || zip.length > 100) {
    errors.zip = formValidationTextData[locale].tooLong
  }

  if (!country) {
    errors.country = formValidationTextData[locale].required
  } else if (typeof country !== 'string' || country.length > 100) {
    errors.country = formValidationTextData[locale].tooLong
  }

  if (!city) {
    errors.city = formValidationTextData[locale].required
  } else if (typeof city !== 'string' || city.length > 100) {
    errors.city = formValidationTextData[locale].tooLong
  }

  if (!street) {
    errors.street = formValidationTextData[locale].required
  } else if (typeof street !== 'string' || street.length > 1000) {
    errors.street = formValidationTextData[locale].tooLong
  }

  if (comment && (typeof comment !== 'string' || comment.length > 1000)) {
    errors.comment = formValidationTextData[locale].tooLong
  }

  return errors
}

export function validateNewItem(item, itemInStoreWithSameUID, locale) {
  const errors = {}
  const { uid,
          collection,
          name,
          type,
          categoryen,
          descriptionen,
          featuresen,
          categoryru,
          descriptionru,
          featuresru,
          images,
          imageFront,
          // imageBack,
          measurea,
          measureb,
          measurec,
          measured,
          price,
          sizes,
          weight,
          schema } = item

  if (!uid) {
    errors.uid = formValidationTextData[locale].required
  } else if (typeof uid !== 'string' || uid.length > 100) {
    errors.uid = formValidationTextData[locale].tooLong
  } else if (itemInStoreWithSameUID) {
    errors.uid = formValidationTextData[locale].saveUID
  }

  if (!collection) {
    errors.collection = formValidationTextData[locale].required
  } else if (typeof collection !== 'string' || collection.length > 100) {
    errors.collection = formValidationTextData[locale].tooLong
  }

  if (!name) {
    errors.name = formValidationTextData[locale].required
  } else if (typeof name !== 'string' || name.length > 100) {
    errors.name = formValidationTextData[locale].tooLong
  }

  if (!type) {
    errors.type = formValidationTextData[locale].required
  } else if (typeof type !== 'string' || type.length > 100) {
    errors.type = formValidationTextData[locale].tooLong
  }

  if (!categoryen) {
    errors.categoryen = formValidationTextData[locale].required
  } else if (typeof categoryen !== 'string' || categoryen.length > 100) {
    errors.categoryen = formValidationTextData[locale].tooLong
  }

  if (!descriptionen) {
    errors.descriptionen = formValidationTextData[locale].required
  } else if (typeof descriptionen !== 'string' || descriptionen.length > 1000) {
    errors.descriptionen = formValidationTextData[locale].tooLong
  }

  if (!featuresen) {
    errors.featuresen = formValidationTextData[locale].required
  } else if (typeof featuresen !== 'string' || featuresen.length > 1000) {
    errors.featuresen = formValidationTextData[locale].tooLong
  }

  if (!categoryru) {
    errors.categoryru = formValidationTextData[locale].required
  } else if (typeof categoryru !== 'string' || categoryru.length > 100) {
    errors.categoryru = formValidationTextData[locale].tooLong
  }

  if (!descriptionru) {
    errors.descriptionru = formValidationTextData[locale].required
  } else if (typeof descriptionru !== 'string' || descriptionru.length > 1000) {
    errors.descriptionru = formValidationTextData[locale].tooLong
  }

  if (!featuresru) {
    errors.featuresru = formValidationTextData[locale].required
  } else if (typeof type !== 'string' || featuresru.length > 1000) {
    errors.featuresru = formValidationTextData[locale].tooLong
  }

  if (!images) {
    errors.images = formValidationTextData[locale].required
  } else if (typeof type !== 'string' || featuresru.length > 1000) {
    errors.images = formValidationTextData[locale].tooLong
  }

  if (!imageFront) errors.imageFront = 'No Front Image'
  // if (!imageBack) errors.imageBack = 'No Back Image.'

  if (measurea && !Number.isInteger(+measurea)) {
    errors.measurea = formValidationTextData[locale].wrongPhone
  }

  if (measureb && !Number.isInteger(+measureb)) {
    errors.measureb = formValidationTextData[locale].wrongPhone
  }

  if (measurec && !Number.isInteger(+measurec)) {
    errors.measurec = formValidationTextData[locale].wrongPhone
  }

  if (measured && !Number.isInteger(+measured)) {
    errors.measured = formValidationTextData[locale].wrongPhone
  }

  if (!price) {
    errors.price = formValidationTextData[locale].required
  } else if (!Number.isInteger(+measurec)) {
    errors.price = formValidationTextData[locale].tooLong
  }

  if (!sizes) {
    errors.amount = formValidationTextData[locale].wrongValue
  } else {
    sizes.forEach((size, i) => {
      const { name, amount } = size
      if (!name) errors[`name_${i}`] = formValidationTextData[locale].wrongValue
      if (!Number.isInteger(+amount)) errors[`amount_${i}`] = formValidationTextData[locale].wrongValue
    })
  }

  if (!Number.isInteger(+weight)) {
    errors.weight = formValidationTextData[locale].tooLong
  }

  if (schema && (typeof schema !== 'string' || schema.length > 200)) {
    errors.schema = formValidationTextData[locale].tooLong
  }

  return errors
}

export function calculateDeliveryCost(bucket, country = '', type, storeItems) {
  let weight = 0
  let deliveryCost = 0

  if (type === 'pickup') return deliveryCost

  country = country.toLowerCase()

  const russian = country === 'россия' || country === 'russia'

  bucket.forEach((item) => {
    weight += storeItems[item.uid].weight * item.amount
  })

  if (russian) {
    deliveryCost = 30000

    if (weight > 2000) {
      deliveryCost = 100000
    } else if (weight > 1500) {
      deliveryCost = 85000
    } else if (weight > 1000) {
      deliveryCost = 75000
    } else if (weight > 500) {
      deliveryCost = 55000
    } else if (weight > 200) {
      deliveryCost = 45000
    } else if (weight > 100) {
      deliveryCost = 35000
    }
  } else {
    deliveryCost = 40000

    if (weight > 1000) {
      deliveryCost = 280000
    } else if (weight > 500) {
      deliveryCost = 160000
    } else if (weight > 300) {
      deliveryCost = 100000
    } else if (weight > 200) {
      deliveryCost = 90000
    }
  }

  return deliveryCost
}

// export function calculateDeliveryCost(bucket, country = '', type) {
//   let deliveryCost = 0
//
//   if (type === 'pickup') return deliveryCost
//
//   country = country.toLowerCase()
//
//   const russian = country === 'россия' || country === 'russia'
//
//   bucket.forEach((item) => {
//     switch (item.type) {
//       case 'pants':
//       case 't-shirts':
//       case 'longsleeves':
//       case 'hats':
//         if (deliveryCost < (russian ? 35000 : 70000)) {
//           deliveryCost = russian ? 35000 : 70000
//         }
//         break
//       case 'hoodies':
//       case 'coats':
//       case 'shoes':
//       case 'bags':
//       case 'suites':
//         if (deliveryCost < (russian ? 50000 : 100000)) {
//           deliveryCost = russian ? 50000 : 100000
//         }
//         break
//       default:
//         if (deliveryCost < (russian ? 35000 : 50000)) {
//           deliveryCost = russian ? 35000 : 50000
//         }
//         break
//     }
//   })
//
//   return deliveryCost
// }

export function getEmptyOrder(locale) {
  const emptyOrder = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    deliveryType: 'rupost',
    deliveryCost: 0,
    country: 'Russia',
    city: '',
    zip: '',
    street: '',
    // house: '',
    // building: '',
    // construction: '',
    // flat: '',
    comment: '',
    errors: {}
  }

  if (locale === 'ru') emptyOrder.country = 'Россия'

  return emptyOrder
}

export function getShowRoomAddress(locale) {
  if (locale === 'en') {
    return {
      country: 'Russia',
      city: 'Saint Petersburg',
      zip: '192007',
      street: 'Borovaya 116',
      // house: '116',
      // building: '',
      // construction: '',
      // flat: '',
    }
  } else if (locale === 'ru') {
    return {
      country: 'Россия',
      city: 'Санкт-Петербург',
      zip: '192007',
      street: 'ул. Боровая 116',
      // house: '116',
      // building: '',
      // construction: '',
      // flat: '',
    }
  }
}

export function getDeliveryType(delivery, locale) {
  if (delivery === 'rupost') {
    return ordersTextData[locale].ruPost
  } else if (delivery === 'pickup') {
    return ordersTextData[locale].pickup
  }
}

export function checkAndSaveUserUpdates(user, order) {
  const { address, delivery, firstName, lastName, phone } = order
  const { country, zip, city, street, house, building, construction, flat } = address
  const updates = {}

  if (!user.firstName || user.firstName !== firstName ||
      !user.lastName || user.lastName !== lastName ||
      !user.phone || user.phone !== phone) {
        updates.displayName = firstName
        updates.firstName = firstName
        updates.lastName = lastName
        updates.phone = phone
      }

  if (delivery.type !== 'pickup' &&
        (!user.address ||
         user.address.country !== country ||
         user.address.city !== city ||
         user.address.zip !== zip ||
         user.address.street !== street ||
         user.address.house !== house ||
         user.address.building !== building ||
         user.address.construction !== construction ||
         user.address.flat !== flat)) {
           updates.address = address
         }

  return updates
}

export function calculateBucketTotal(bucket, items) {
  let total = 0

  if (!bucket || !bucket.length) {
    return total
  } else {
    bucket.forEach((item) => {
      const { uid, amount } = item
      const itemInfo = items[uid]
      const { price, sale } = itemInfo

      if (sale) {
        total += sale * amount
      } else {
        total += price * amount
      }
    })

    return total
  }
}

export function calculateOrderTotal(items) {
  let total = 0

  if (!items || !items.length) {
    return total
  } else {
    items.forEach((item) => {
      const { amount, price } = item
      total += price * amount
    })
    return total
  }
}

export function parseURLQuery(query) {
  const params = {}

  query.split('&').forEach((part) => {
    const arr = part.split('=')
    params[arr[0]] = arr[1]
  })

  return params
}

export function shuffle(array) {
  let currentIndex = array.length, temporaryValue, randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export function sortItems(items) {
  items.sort((a, b) => {
    return new Date(b.date) - new Date(a.date)
  })

  return items
}

export function getDaysDifferenceBetweenTwoDates(dateOne, dateTwo) {
  const date1 = new Date(dateOne)
  const date2 = new Date(dateTwo)
  const diffTime = Math.abs(date2.getTime() - date1.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

// ₽
export function makePriceString(locale, price, drate) {
  return locale === 'ru' ? `${price / 100} руб.` : `$${Math.ceil(price / 100 / drate)}`
}

export function debounce(func, wait, immediate) {
	let timeout

	return function() {
		const context = this, args = arguments
		const later = function() {
			timeout = null
			if (!immediate) func.apply(context, args)
		}
		const callNow = immediate && !timeout

		clearTimeout(timeout)

		timeout = setTimeout(later, wait)

		if (callNow) func.apply(context, args)
	}
}

export function getRandomArrayElement(ar) {
  const item = ar[Math.floor(Math.random()*ar.length)];
  return item
}

export function getFourRandomArrayElements(ar) {
const shuffledArray = ar.sort(() => 0.5 - Math.random())
const selectedFour = shuffledArray.slice(0, 4)
return selectedFour
}
