import React from 'react'

export default function HeartLoader() {
  return (
    <div className='loader'>
      <div className='loader__inner'>
        <div className='lds-heart'><div></div></div>
      </div>
    </div>
  )
}
