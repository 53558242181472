export const contactsEN = {
  header: 'Contacts',
  showroom: 'Pickup point',
  showroomAddress: '192007, Borovaya 116, Saint Petersburg, Russia',
  workingHours: 'Monday - Friday / 12:00 - 19:00',
  links: 'Links',
  phone: 'Phone',
  email: 'Email',
  note: 'Please connect with us before visit',
  legal: 'Legal information'
}

export const contactsRU = {
  header: 'Контакты',
  showroom: 'Точка самовывоза',
  showroomAddress: '192007, Боровая 116, Санкт-Петербург',
  workingHours: 'Понедельник - Пятница / 12:00 - 19:00',
  links: 'Ссылки',
  phone: 'Телефон',
  email: 'Электронная почта',
  note: 'Пожалуйста, свяжитесь с нами перед тем как захотите зайти',
  legal: 'Правовая информация'
}

export const contactsTextData = {
  en: contactsEN,
  ru: contactsRU
}
