import React from 'react'

const DeliveryRU = () => {
  return (
    <div className='info container-padding'>
      <h2 className='page-header base-header'>
        Доставка и Оплата
      </h2>
      <h3 className='base-text font-bold'>
        Юр. информация
      </h3>
      <div className='base-text'>
        <p>
          ИП Чиркова Любовь Сергеевна <br/>
          ОГРНИП 318784700282252 <br/>
          195027, Россия, г. Санкт-Петербург, пр. Энергетиков, 9 к6
        </p>
      </div>
      <h3 className='base-text font-bold'>
        Конфиденциальность
      </h3>
      <div className='base-text'>
        <p>
          Любая информация личного характера, которую Вы предоставите theindigostuff.com, будет использована нами исключительно для исполнения взятых на себя обязательств. Такого рода информация не будет передана третьим лицам без Вашего предварительного согласия. Мы можем предоставить Вам доступ к Вашим личным данным по Вашей просьбе. Если эта информация неполная или содержит неверные данные, она может быть исправлена или удалена.
        </p>
        <p>
        Как только мы получим Ваш заказ, Вам автоматически будет отправлено электронное сообщение, подтверждающее оформление нами Вашего заказа. Мы сохраняем за собой право не доставлять товар, если на момент размещения заказа данные о его наличии на складе были неверными. В том случае, если мы не можем доставить товар в соответствии с условиями, указанными в подтверждении заказа, мы свяжемся в Вами и предоставим Вам возможность аннулировать заказ. Обращаем Ваше внимание на то, что из-за особенностей цветового профиля Вашего компьютера, цветовая гамма товаров на фотографиях, сделанных в магазине, может отличаться от реальной. theindigostuff.com не несет ответственность за любые расхождения такого рода.
        </p>
      </div>
      <h3 className='base-text font-bold'>
        Доставка
      </h3>
      <div className='base-text'>
        <p>
          Доставка всех заказов осуществляется государственными почтовыми сетями. Как правило, товары отгружаются на следующий рабочий день после дня размещения заказа. В периоды действия специальных предложений и распродаж сроки отправки могут увеличиваться до нескольких рабочих дней. Как только мы отправим ваш заказ, вы обязательно получите соответствующее уведомление на электронный адрес, указанный в вашем профиле.
        </p>
        <p>
          После отправки заказа мы пришлём вам номер почтового отправления (tracking number) на электронный адрес, указанный в вашем профиле. С помощью этого номера вы сможете отследить текущее место нахождения вашей посылки на сайте почты. В случае доставки государственными почтовыми сетями в день, когда посылка достигнет финального пункта назначения, статус вашей посылки на сайте службы доставки изменится на "Ожидает адресата в месте вручения", что означает, что вы можете получить посылку в вашем почтовом отделении, предъявив документ, удостоверяющий личность получателя. Бумажное извещение о пришедшей посылке обычно поступает на ваш физический почтовый ящик в течение нескольких дней после того, как она была доставлена в финальный пункт назначения, поэтому мы рекомендуем проверять статус посылки самостоятельно на сайте почтовой службы.
        </p>
      </div>
      <h3 className='base-text font-bold'>
        Самовывоз
      </h3>
      <div className='base-text'>
        <p>
          Обращаем ваше внимание, что вы также можете забрать вашу покупку прямо из магазина. Для этого необходимо выбрать опцию "Самовывоз" во время оформления заказа.
        </p>
      </div>
      <h3 className='base-text font-bold'>
        Оплата
      </h3>
      <div className='base-text'>
        <p>
          theindigostuff.com принимает оплату по банковским картам VISA и Mastercard. Вся сумма платежа начисляется (списывается) в момент оформления заказа. Все платежные операции закодированы и абсолютно безопасны. theindigostuff.com сохраняет право удерживать товар в течение двух рабочих дней с целью обеспечения безопасного платежа.
        </p>
        <p>
          Обращаем ваше внимание на то, что некоторые банки используют дополнительный уровень безопасности 3-D Secure, требующий ввода уникального кода подтверждения, предоставляемого банком для каждой операции с помощью карточки разовых кодов, sms-сообщения или специального устройства. Пожалуйста, если во время оплаты вы не получаете sms-сообщения от банка, выпустившего вашу карту, и у вас нет карточки разовых кодов или специального устройства, обратитесь в свой банк, и вам обязательно предоставят одно из перечисленных средств.
        </p>
      </div>
      <h3 className='base-text font-bold'>
        Валюта
      </h3>
      <div className='base-text'>
        <p>
          Оплата совершается в российских рублях по внутреннему курсу вашего банка. Магазин theindigostuff.com не может быть признан ответственным за любые колебания валютных курсов, а также валютные курсы, установленные банками для конвертации других валют в рубли.
        </p>
      </div>
    </div>
  )
}

export default DeliveryRU
