export const orderStatusesEN = {
  unpaid: 'Unpaid',
  paid: 'Paid',
  packed: 'Packed',
  sent: 'Sent',
  delivered: 'Delivered',
  canceled: 'Canceled',
  all: 'All',
  update: 'Update',
  loading: 'Loading...',
  pay: 'Pay'
}

export const orderStatusesRU = {
  unpaid: 'Неоплачен',
  paid: 'Оплачен',
  packed: 'Упакован',
  sent: 'Отправлен',
  delivered: 'Доставлен',
  canceled: 'Отменён',
  all: 'Все',
  update: 'Обновить',
  loading: 'Загрузка...',
  pay: 'Оплатить'
}

export const orderStatusesData = {
  en: orderStatusesEN,
  ru: orderStatusesRU
}
