export const itemEN = {
  addToBucket: 'Add to Cart',
  notAvailable: 'Sold out',
  alreadyInCart: 'Already in Cart',
  amount: 'Amount',
  price: 'Price',
  save: 'Save',
  sale: 'Sale',
  preoder: 'Preorder',
  date: 'Date/Order',
  hide: 'Hide',
  size: 'Size',
  preorder: 'Preorder',
  // preorderNote: 'Preorder. This item will be sent in 10-20 days after order will be paid.',
  // preorderNoteSecond: 'Preorder. This order will be sent in 10-20 days after payment.',
  moreStuff: 'More Stuff',
  soon: 'Soon'
}

export const itemRU = {
  addToBucket: 'В корзину',
  notAvailable: 'Закончились',
  alreadyInCart: 'Уже в Корзине',
  amount: 'Кол-во',
  price: 'Цена',
  save: 'Сохранить',
  sale: 'Сэйл',
  preoder: 'Предзаказ',
  date: 'Дата/Порядок',
  hide: 'Спрятать',
  size: 'Размер',
  preorder: 'Предзаказ',
  // preorderNote: 'Предзаказ. Эта вещь будет отправлена в течение 10-20 дней после оплаты заказа.',
  // preorderNoteSecond: 'Предзаказ. Этот заказ будет отправлен в течение 10-20 дней после оплаты.',
  moreStuff: 'Больше Стаффа',
  soon: 'Скоро'
}

export const itemTextData = {
  en: itemEN,
  ru: itemRU
}
