import React from 'react'
import AccountMenuItem from './AccountMenuItem'
import { accountTextData } from '../../data/account'

const AccountMenu = ({ section, selectSection, locale }) => {
  return (
    <ul className='account-menu'>
      <AccountMenuItem section={section} handler={selectSection} name={accountTextData[locale].account} value='Account' />
      <AccountMenuItem section={section} handler={selectSection} name={accountTextData[locale].changePwd} value='Change Password' />
      <AccountMenuItem section={section} handler={selectSection} name={accountTextData[locale].changeEmail} value='Change Email' />
    </ul>
  )
}

export default AccountMenu
