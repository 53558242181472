import React from 'react'
import ItemListHomePage from '../Items/ItemListHomePage'
import { sortItems } from '../../utils'

const Stuff = ({ items, locale, drate }) => {
  if (!items || !items.length) return null

  return (
    <div className='stuff'>
      <ItemListHomePage items={sortItems(items.filter((item) =>
          !item.hide &&
          !item.market &&
          item.collection !== 'pm-x-indigo-stuff' &&
          item.collection !== 'emoland-x-indigo-stuff' &&
          item.collection !== 'psychea-x-indigo-stuff'))}
          locale={locale}
          drate={drate}
          project='indigo' />
    </div>
  )
}

export default Stuff
