export const fbErrorsEN = {
  wrongEmailPwd: 'Wrong email or password.',
  emailInUse: 'Email is already in use by another account.'
}

export const fbErrorsRU = {
  wrongEmailPwd: 'Неправильные данные для входа.',
  emailInUse: 'Адрес уже используется другим аккаунтом.'
}

export const fbErrorsTextData = {
  en: fbErrorsEN,
  ru: fbErrorsRU
}
