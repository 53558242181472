export const authEN = {
  email: 'Email',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  forgotPassword: 'I Forgot Password',
  signUpSubmit: 'Register',
  loginSubmit: 'Login',
  forgotPasswordSubmit: 'Reset Password',
  backToLogin: 'Back to Login',
  passwordRecovery: 'Password Recovery',
  resetPassword: 'Reset Password',
  checkEmail: 'Check email.',
  loading: 'Loading...',
  cart: 'Cart',
  ordering: 'Ordering',
  signUp: 'register',
  login: 'login',
  signUpHeader: 'Registration',
  loginHeader: 'Login',
  emptyBucket: 'SHOPPING CART IS EMPTY.',
  emptyChangedBucket: 'Sorry, items are sold out :(',
  please: 'Please',
  or: 'or',
  forRegistration: 'Start purchasing to create an account.'
}

export const authRU = {
  email: 'Email',
  password: 'Пароль',
  confirmPassword: 'Повторите Пароль',
  forgotPassword: 'Я Забыл Пароль',
  signUpSubmit: 'Зарегистрироваться',
  loginSubmit: 'Войти',
  forgotPasswordSubmit: 'Сбросить Пароль',
  backToLogin: 'Обратно на Вход',
  passwordRecovery: 'Восстановление Пароля',
  resetPassword: 'Сбросить Пароль',
  checkEmail: 'Проверь почту.',
  loading: 'Загрузка...',
  cart: 'Корзина',
  ordering: 'Заказ',
  signUp: 'зарегистрируйтесь',
  login: 'войдите',
  signUpHeader: 'Регистрация',
  loginHeader: 'Вход',
  emptyBucket: 'КОРЗИНА ПУСТА.',
  emptyChangedBucket: 'Извини, нет в наличии :(',
  please: 'Пожалуйста',
  or: 'или',
  forRegistration: 'Для регистрации начните совершать покупку.'
}

export const authTextData = {
  en: authEN,
  ru: authRU
}
