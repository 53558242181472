import React, { Component } from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import { emailVerifyTextData } from '../../data/emailVerify'
import { pwdResetTextData } from '../../data/pwdReset'
import { emailRecoverTextData } from '../../data/emailRecover'
import { firebase } from '../../firebase'
import { setLocale, parseURLQuery } from '../../utils'
import { formValidationTextData } from '../../data/formValidation'
import Loader from '../common/Loader'
import InputBlock from '../common/InputBlock'

class CustomEmailHandler extends Component {
  state = {
    loading: true,
    error: null,
    success: null,
    newPassword: '',
    errors: {},
    mode: ''
  }

  componentWillMount() {
    const query = this.props.location.search.substr(1)
    const params = parseURLQuery(query)
    const actionCode = params.oobCode || ''
    const mode = params.mode

    this.setState({
      mode: mode
    })

    setLocale(params.lang)

    // check other params if needed

    switch (mode) {
      case 'resetPassword':
        this.setState({
          loading: false
        })
        break
      case 'recoverEmail':
        // let restoredEmail = null

        firebase.auth.checkActionCode(actionCode)
          .then((info) => {
            // restoredEmail = info['data']['email']
            // Revert to the old email.
            return firebase.auth.applyActionCode(actionCode)
          })
          .then(() => {
            // Account email reverted to restoredEmail
            this.setState({
              success: true,
              loading: false
            })

            // // TODO: Display a confirmation message to the user.
            //
            // // You might also want to give the user the option to reset their password
            // // in case the account was compromised:
            // auth.sendPasswordResetEmail(restoredEmail).then(function() {
            //   this.setState({
            //     loading: false,
            //     success: true
            //   })
            // })
            // .catch((error) => {
            //   this.setState({
            //     loading: false,
            //     error: true
            //   })
            // })

          })
          .catch((error) => {
            this.setState({
              loading: false,
              error: true
            })
          })
        break
      case 'verifyEmail':
        firebase.auth.applyActionCode(actionCode)
          .then((res) => {
            this.setState({
              loading: false,
              success: true
            })
          })
          .catch((error) => {
            this.setState({
              loading: false,
              error: true
            })
          })
        break
      default:
        this.setState({
          loading: false,
          error: true
        })
    }
  }

  render() {
    const { locale } = this.props
    const { success, error, mode, loading, errors, newPassword } = this.state

    let content

    switch (mode) {
      case 'resetPassword':
        content =
          <form onSubmit={this.onSubmit} className='form'>
            {
              loading && <Loader />
            }
            <InputBlock
              errors={errors}
              handler={this.onInputChange}
              name='newPassword'
              value={newPassword}
              type='password'
              placeholder={pwdResetTextData[locale].newPwd}
            />
            { error ? <div className='form__error'>{error}</div> : null }
            <div className='form__submit-block'>
              <button className='button' type='submit'>
                {pwdResetTextData[locale].submit}
              </button>
            </div>
          </form>

        if (success) {
          content =
            <div>
              <div className='email-verification__item'>
                {pwdResetTextData[locale].verified}
              </div>
              <div>
                <NavLink to='/' className='link'>
                  {pwdResetTextData[locale].link}
                </NavLink>
              </div>
            </div>
        }

        if (error) {
          content =
            <div className=' red-text'>
              {pwdResetTextData[locale].error}
            </div>
        }

        return (
          <div className='email-verification login'>
            <h2 className='page-header base-header'>
              {pwdResetTextData[locale].header}
            </h2>
            <div className='base-text font-bold'>
              <div>
                {content}
              </div>
            </div>
          </div>
        )
      case 'recoverEmail':
        if (success) {
          content =
            <div>
              <div className='email-verification__item'>
                {emailRecoverTextData[locale].recovered}
              </div>
              <div>
                <NavLink to='/' className='link'>
                  {emailRecoverTextData[locale].link}
                </NavLink>
              </div>
            </div>
        }

        if (error) {
          content =
            <div className=' red-text'>
              {emailRecoverTextData[locale].error}
            </div>
        }

        return (
          <div className='email-verification'>
            <h2 className='page-header base-header'>
              {emailRecoverTextData[locale].header}
            </h2>
            <div className='base-text font-bold'>
              <div>
                {content}
              </div>
            </div>
          </div>
        )
      case 'verifyEmail':
        if (success) {
          content =
            <div>
              <div className='email-verification__item'>
                {emailVerifyTextData[locale].verified}
              </div>
              <div>
                <NavLink to='/' className='link'>
                  {emailVerifyTextData[locale].link}
                </NavLink>
              </div>
            </div>
        }

        if (error) {
          content =
            <div className=' red-text'>
              {emailVerifyTextData[locale].error}
            </div>
        }

        return (
          <div className='email-verification'>
            <h2 className='page-header base-header'>
              {emailVerifyTextData[locale].header}
            </h2>
            <div className='base-text font-bold'>
              <div>
                {content}
              </div>
            </div>
          </div>
        )
      default:
        return <Redirect to='' />
    }
  }

  onInputChange = element => e => {
    this.setState({
      [element]: e.target.value
    })
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.validate()
  }

  validate = () => {
    const { locale } = this.props
    const { newPassword } = this.state
    const errors = {}

    if (!newPassword) errors.newPassword = formValidationTextData[locale].required
    if (newPassword && newPassword.length < 6) errors.newPassword = formValidationTextData[locale].wrongPwd

    if (Object.keys(errors).length) {
      this.setState({ errors })
    } else {
      this.setState({
        errors: errors,
        loading: true,
      }, this.resetPwd)
    }
  }

  resetPwd = () => {
    const query = this.props.location.search.substr(1)
    const { newPassword } = this.state
    const params = parseURLQuery(query)
    const actionCode = params.oobCode || ''

    // check other params if needed

    firebase.auth.verifyPasswordResetCode(actionCode)
      .then((email) => {
        firebase.auth.confirmPasswordReset(actionCode, newPassword)
          .then((res) => {
            this.setState({
              loading: false,
              success: true
            })
          }).catch((error) => {
            this.setState({
              loading: false,
              error: true
            })
          })
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: true
        })
      })
  }
}

export default CustomEmailHandler

// Error Codes
// auth/expired-action-code
// Thrown if the action code has expired.
// auth/invalid-action-code
// Thrown if the action code is invalid. This can happen if the code is malformed or has already been used.
// auth/user-disabled
// Thrown if the user corresponding to the given action code has been disabled.
// auth/user-not-found
// Thrown if there is no user corresponding to the action code. This may have happened if the user was deleted between when the action code was issued and when this method was called.
