import React from 'react'

const LoaderSmall = () => {
  return (
    <div className='container-padding'>
      <div className='lds-heart lds-heart--indigo'><div></div></div>
    </div>
  )
}

export default LoaderSmall
