import React from 'react'
import LoginForm from '../Account/LoginForm'
import { Redirect } from 'react-router-dom'

const Login = ({ user, locale, checkOutPage }) => {
  if (user && !checkOutPage) return <Redirect to='/orders' />

  return (
    <div className='login container-padding'>
      <LoginForm locale={locale} />
    </div>
  )
}

export default Login
