import React from 'react'
import Cube from './Cube'
// import Rubik from './Rubik'

// <Rubik />


const Loader = () => {
  return (
    <div className='loader'>
      <div className='loader__inner'>


      <Cube />
      </div>
    </div>
  )
}

export default Loader
