import React, { Component } from 'react'
import OrderConfirm from './OrderConfirm'
import OrderForm from './OrderForm'
import { saveOrderToLocalStorage, getOrderFromLocalStorage, validateOrderForm, calculateDeliveryCost, getShowRoomAddress, calculateBucketTotal, getEmptyOrder } from '../../utils'

class OrderMain extends Component {
  constructor(props){
    super()
    this.state = getEmptyOrder(props.locale)
  }

  componentDidMount() {
    const { user, bucket, locale, items } = this.props
    let state = getOrderFromLocalStorage()

    if (!state) state = getEmptyOrder(locale)
    if (!state.email) state.email = user.email

    state.errors = {}
    if (user.firstName) state.firstName = user.firstName
    if (user.lastName) state.lastName = user.lastName
    if (user.phone) state.phone = user.phone
    if (user.address) {
      if (user.address.country) state.country = user.address.country
      if (user.address.city) state.city = user.address.city
      if (user.address.zip) state.zip = user.address.zip
      if (user.address.street) state.street = user.address.street
      // if (user.address.house) state.house = user.address.house
      // if (user.address.building) state.building = user.address.building
      // if (user.address.construction) state.construction = user.address.construction
      // if (user.address.flat) state.flat = user.address.flat
    }

    state.deliveryCost = calculateDeliveryCost(bucket, state.country, state.deliveryType, items)

    this.setState(state)
  }

  render() {
    const { bucketAmountWasChanged, items, locale, checkoutUrl, orderConfirm, drate, createOrderError } = this.props
    const { errors } = this.state

    return (
      <div>
        {
          !Object.keys(errors).length && checkoutUrl && orderConfirm ?
            <OrderConfirm
              order={orderConfirm}
              checkoutUrl={checkoutUrl}
              total={calculateBucketTotal(orderConfirm.items, items)}
              locale={locale}
              drate={drate}
            /> :
            <OrderForm
              order={this.state}
              onInputChange={this.onInputChange}
              onSubmit={this.onSubmit}
              bucketAmountWasChanged={bucketAmountWasChanged}
              createOrderError={createOrderError}
              onInputBlur={this.onInputBlur}
              locale={locale}
              drate={drate}
            />
        }
      </div>
    )
  }

  onInputChange = element => e => {
    const { locale, bucket, items } = this.props
    const value = e.target.value

    if (element === 'deliveryType') {
      const { user } = this.props
      let address = {}

      if (value === 'pickup') {
        address = getShowRoomAddress(locale)
      } else if (user.address && user.address.country) {
        address = user.address
      } else {
        address = getEmptyOrder(locale)
      }

      this.setState({
        country: address.country,
        city: address.city,
        zip: address.zip,
        street: address.street,
        // house: address.house,
        // building: address.building,
        // construction: address.construction,
        // flat: address.flat,
        deliveryType: value,
        deliveryCost: calculateDeliveryCost(bucket, address.country, value, items)
      })
    } else {
      this.setState({
        [element]: value
      })
    }
  }

  onInputBlur = () => {
    saveOrderToLocalStorage(this.state)
    this.setState({
      deliveryCost: calculateDeliveryCost(this.props.bucket, this.state.country, this.state.deliveryType, this.props.items)
    })
  }

  onSubmit = e => {
    e.preventDefault()
    this.validate()
  }

  validate = () => {
    const errors = validateOrderForm(this.state, this.props.locale)

    // console.log(errors)

    if (Object.keys(errors).length) {
      this.setState({ errors })
    } else {
      this.setState({
        errors: errors,
      }, this.createOrder)
    }
  }

  createOrder = () => {
    saveOrderToLocalStorage(this.state)

    const { user, bucket, items, locale } = this.props
    const { firstName, lastName, comment, deliveryType, deliveryCost, email, phone, ...address } = this.state

    const orderAddress = {
      zip: address.zip.trim(),
      country: address.country.trim(),
      city: address.city.trim(),
      street: address.street.trim(),
      // house: address.house.trim(),
    }

    // if (address.building) orderAddress.building = address.building.trim()
    // if (address.construction) orderAddress.construction = address.construction.trim()
    // if (address.flat) orderAddress.flat = address.flat.trim()
    // console.log(items)
    // console.log('----')

    const order = {
      user: user.uid,
      email: email,
      phone: +phone,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      address: orderAddress,
      delivery: {
        type: deliveryType,
        cost: deliveryType === 'pickup' ? 0 : deliveryCost
      },
      status: 'unpaid',
      created: +new Date(),
      items: bucket.map((bucketItem) => {
        const { uid } = bucketItem
        const { price, sale, images, imageFront } = items[uid]

        if (sale) {
          bucketItem.price = sale
          bucketItem.sale = true
        } else {
          bucketItem.price = price
        }

        if (items[uid].en.preorder && items[uid].ru.preorder) {
          bucketItem.preorder = {}
          bucketItem.preorder.en = items[uid].en.preorder
          bucketItem.preorder.ru = items[uid].ru.preorder
        }

        bucketItem.name = {}
        bucketItem.name.en = `${items[uid].en.category} ${items[uid].name}`
        bucketItem.name.ru = `${items[uid].ru.category} ${items[uid].name}`

        bucketItem.imageFront = bucketItem.imageFront || images[0]

        return bucketItem
      }),
      locale: locale
    }

    // console.log(order)
    // console.log('-----')

    if (comment) order.comment = comment

    this.props.createOrder(order)
  }
}

export default OrderMain
