import React from 'react'

const LoaderLight = () => {
  return (
    <div className='loader loader--light'>
      <div className='loader__inner'>
      </div>
    </div>
  )
}

export default LoaderLight
