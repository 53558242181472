import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { auth } from '../../firebase'
import LoaderLight from '../common/LoaderLight'
import validator from 'email-validator'
import InputBlock from '../common/InputBlock'
import { convertFBCodeMessage } from '../../utils'
import { authTextData } from '../../data/auth'
import { formValidationTextData } from '../../data/formValidation'

class LoginForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      loading: false,
      errors: {},
      error: null
    }
  }

  render() {
    const { locale, noRegWarning } = this.props
    const { email,
            password,
            errors,
            loading,
            error } = this.state

    return (
      <form onSubmit={this.onSubmit} className='form'>
        {
          loading && <LoaderLight />
        }
        <h2 className='form__header page-header base-header'>
          {authTextData[locale].loginHeader}
        </h2>
        <InputBlock
          errors={errors}
          handler={this.onInputChange}
          name='email'
          value={email}
          type='text'
          placeholder={authTextData[locale].email}
        />
        <InputBlock
          errors={errors}
          handler={this.onInputChange}
          name='password'
          value={password}
          type='password'
          placeholder={authTextData[locale].password}
        />
        { error ? <div className='form__error'>{error}</div> : null }
        <div className='form__submit-block form__submit-block--login'>
          <NavLink to='/forgot-password' className='link base-text'>
            {authTextData[locale].forgotPassword}
          </NavLink>
          <button className='button' type='submit'>
            {
              loading ? authTextData[locale].loading : authTextData[locale].loginSubmit
            }
          </button>
        </div>
        {
          noRegWarning ? null : <div className='form__warning base-text'>
            {authTextData[locale].forRegistration}
          </div>
        }
      </form>
    )
  }

  onInputChange = element => e => {
    this.setState({
      [element]: e.target.value
    })
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.validate()
  }

  validate = () => {
    const { locale } = this.props
    const { email, password } = this.state
    const errors = {}

    if (!email) errors.email = formValidationTextData[locale].required
    if (email && !validator.validate(email)) errors.email = formValidationTextData[locale].wrongEmail

    if (!password) errors.password = formValidationTextData[locale].required
    if (password && password.length < 6) errors.password = formValidationTextData[locale].wrongPwd

    if (Object.keys(errors).length) {
      this.setState({ errors })
    } else {
      this.setState({
        errors: errors,
        loading: true,
      }, this.doSignIn)
    }
  }

  doSignIn = () => {
    const { checkOutPage, locale } = this.props
    const { email,
            password } = this.state

    auth.doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ loading: false })
        // if (checkOutPage) {
        //   this.setState({ loading: false })
        // }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          error: convertFBCodeMessage(error, locale),
          loading: false
        })
      })
  }
}

export default LoginForm
