export const accountEN = {
  account: 'account',
  changePwd: 'change password',
  changeEmail: 'change email',
  signOut: 'Log Out',
  currentPwd: 'Password',
  newPwd: 'New Password',
  confirmNewPwd: 'Confirm New Password',
  changePwdSubmit: 'Change Password',
  pwdChanged: 'Password changed',
  emailChanged: 'Email changed',
  verified: 'Verified',
  notVerified: 'not verified',
  currentEmail: 'Current Email',
  newEmail: 'New Email',
  changeEmailSubmit: 'Change Email',
  sendVerificationEmail: 'send again',
  sendVerifyLoading: 'loading...',
  sendVerifySuccess: 'Check email.',
  sendVerifyError: 'Error. Try again.',
  orders: 'My Orders'
}

export const accountRU = {
  account: 'аккаунт',
  changePwd: 'сменить пароль',
  changeEmail: 'сменить email',
  signOut: 'Выйти',
  currentPwd: 'Пароль',
  newPwd: 'Новый Пароль',
  confirmNewPwd: 'Подтвердить Новый Пароль',
  changePwdSubmit: 'Сменить Пароль',
  pwdChanged: 'Пароль изменён',
  emailChanged: 'Email изменён',
  verified: 'Подтверждён',
  notVerified: 'не подтверждён',
  currentEmail: 'Текущий Email',
  newEmail: 'Новый Email',
  changeEmailSubmit: 'Сменить Email',
  sendVerificationEmail: 'выслать ещё раз',
  sendVerifyLoading: 'загрузка...',
  sendVerifySuccess: 'Проверь почту.',
  sendVerifyError: 'Ошибка. Попробуй ещё раз.',
  orders: 'Мои Заказы'
}

export const accountTextData = {
  en: accountEN,
  ru: accountRU
}
