import React, { Component } from 'react'
import LoginForm from '../Account/LoginForm'
import SignUpForm from '../Account/SignUpForm'
import { checkoutTextData } from '../../data/checkout'

class AuthBlock extends Component {
  state = {
    signUpForm: true
  }

  render() {
    const { locale, noRegWarning } = this.props
    const { signUpForm } = this.state
    const loginLinkClass = signUpForm ? 'auth-block__link auth-block__link--bold link' : 'auth-block__link link'
    const signUpLinkClass = signUpForm ? 'auth-block__link link' : 'auth-block__link auth-block__link--bold link'

    return (
      <div className='auth-block'>
        <div className='auth-block__form-choice base-text'>
          {checkoutTextData[locale].please}, <span className={loginLinkClass} onClick={this.selectSignUpForm}>{checkoutTextData[locale].signUp}</span> {checkoutTextData[locale].or} <span className={signUpLinkClass} onClick={this.selectLoginForm}>{checkoutTextData[locale].login}</span>
        </div>
        <div>
          {
            signUpForm ? <SignUpForm locale={locale} /> : <LoginForm checkOutPage={true} locale={locale} noRegWarning={noRegWarning} />
          }
        </div>
      </div>
    )
  }

  selectLoginForm = () => this.setState({ signUpForm: false })

  selectSignUpForm = () => this.setState({ signUpForm: true })
}

export default AuthBlock
