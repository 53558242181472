import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import SwipeCarousel from './SwipeCarousel'
import { itemTextData } from '../../data/item'
import { shuffle } from '../../utils'
import classnames from 'classnames'
import Parser from 'html-react-parser'
import { makePriceString } from '../../utils'

class ItemPage extends Component {
  state = {
    sizeSelected: ''
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillMount() {
    const { amount } = this.props.item
    let sizeSelected = Object.keys(amount)[0]

    for (const prop in amount) {
      if (amount[prop]) sizeSelected = prop
    }

    this.setState({
      sizeSelected: sizeSelected
    })
  }

  render() {
    const { itemId, item, addItemToBucket, bucket, locale, drate } = this.props
    let { itemsArray = [] } = this.props

    const { name, price, schema, amount, measure, type, sale, market } = item
    let { images } = item

    const { features, category, description, preorder } = item[locale]
    const { sizeSelected } = this.state
    const sizeOptions = []

    // item will be available soon
    const soonItem = price === 100

    if (images.length === 2) {
      images = images.concat(images)
    }

    const priceClass = classnames({
      'item__price': true,
      'item__price--line-through': !!sale,
      'item__price--soon': soonItem
    })

    for (const prop in amount) {
      if (amount[prop]) sizeOptions.push(prop)
    }

    if (!sizeOptions.length) sizeOptions.push(sizeSelected)

    const itemInBucket = bucket[bucket.findIndex(item => item.uid === itemId && item.size === sizeSelected)]
    const itemDisabled = amount[sizeSelected] ? false : true

    let btnText = soonItem ? itemTextData[locale].soon : itemTextData[locale].addToBucket

    if (itemDisabled) btnText = itemTextData[locale].notAvailable
    if (itemInBucket) btnText = itemTextData[locale].alreadyInCart

    const itemsWithoudSoldOutItems = itemsArray.filter(it => {
      const { amount, price } = it
      // item will be available soon
      const soonItem = price === 100

      let flag = false

      for (let size in amount) {
        if (amount[size]) flag = true
      }

      if (flag && !soonItem) return it
    })

    const typeItems = market ? shuffle(itemsWithoudSoldOutItems.filter((it) => !it.hide && it.market && it.uid !== itemId)).slice(0, 8) : shuffle(itemsWithoudSoldOutItems.filter((it) => !it.hide && !it.market && it.uid !== itemId)).slice(0, 8)

    return (
      <div className='item'>
        <div className='item__main-block'>
          <div className='item__images-block'>
            <SwipeCarousel images={images} />
          </div>
          <div className='item__info-block'>
            <div className='item__info-block-inner'>
              <h1 className='item__name base-header'>
                <div>
                  <div className='font-thin'>{category}</div>
                  <div className='item__name-main font-bold uppercase'>{name}</div>
                </div>
              </h1>
              <div className='item__price-container'>
                <div className={priceClass}>
                  {makePriceString(locale, price, drate)}
                </div>
              </div>
              {
                !sale ? null :
                <div className='item__price-container'>
                  <div className='item__price item__price--sale base-text'>
                    {makePriceString(locale, sale, drate)}
                  </div>
                </div>
              }
              <div className='item__add-block'>
                <div className='item__add-btn'>
                  <button className='button' disabled={itemDisabled || itemInBucket || soonItem} onClick={addItemToBucket(itemId, sizeSelected, type)}>
                    {btnText}
                  </button>
                </div>
                <div className='item__select-size'>
                  <select className='input-select' id='size' onChange={this.onInputChange('sizeSelected')} value={sizeSelected} disabled={itemDisabled || sizeOptions.length === 1}>
                    {
                      sizeOptions.map((size) => {
                        const name = size === 'onesize' ? 'ONE SIZE' : size
                        return (
                          <option value={size} key={size}>
                            {name}
                          </option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
              {
                preorder ? <div className='item__warning'>{preorder}</div> : null
              }
              <p className='item__description base-text'>{Parser(description)}</p>
              <ul className='item__features base-text'>
                {
                  features.map((feature) => {
                    return (
                      <li key={feature}>
                        {feature}
                      </li>
                    )
                  })
                }
              </ul>
              {
                schema &&
                <div className='item__measurements base-text'>
                  <img src={schema} width='100' alt='Measurements' />
                  {
                    measure ?
                    <div className='item__measure-items'>
                      {
                        measure[sizeSelected].a ?
                        <div>
                          A – {measure[sizeSelected].a} {locale === 'en' ? 'cm' : 'см'}
                        </div> : null
                      }
                      {
                        measure[sizeSelected].b ?
                        <div>
                          B – {measure[sizeSelected].b} {locale === 'en' ? 'cm' : 'см'}
                        </div> : null
                      }
                      {
                        measure[sizeSelected].c ?
                        <div>
                          C – {measure[sizeSelected].c} {locale === 'en' ? 'cm' : 'см'}
                        </div> : null
                      }
                      {
                        measure[sizeSelected].d ?
                        <div>
                          D – {measure[sizeSelected].d} {locale === 'en' ? 'cm' : 'см'}
                        </div> : null
                      }
                      {
                        measure[sizeSelected].e ?
                        <div>
                          E – {measure[sizeSelected].e} {locale === 'en' ? 'cm' : 'см'}
                        </div> : null
                      }
                    </div> : null
                  }
                </div>
              }
            </div>
            {
              !typeItems.length ? null :
              <div className='item__more-header secondary-header'>
                {itemTextData[locale].moreStuff}:
              </div>
            }
          </div>
        </div>
        {
          !typeItems.length ? null :
          <div className='item__more'>
            <div className='item__more-items'>
              {
                typeItems.map((item, i) => {
                  const { uid, name, price, sale, images = [], imageFront, market } = item
                  const { category } = item[locale]

                  const priceClass = classnames({
                    'item__price item__price--additional small-text': true,
                    'line-through': sale,
                  })

                  const itemLink = market ? `/market/${uid}` : `/stuff/${uid}`

                  const namePartOne = locale === 'ru' ? <span className='font-thin'>{category}</span> : <span className='font-bold uppercase'>{name}</span>
                  const namePartTwo = locale === 'ru' ? <span className='font-bold uppercase'>{name}</span> : <span className='font-thin'>{category}</span>

                  return (
                    <NavLink key={`${itemId}${i}`} to={itemLink} className='item__more-item'>
                      <div className='item__more-img-wrapper'>
                        <img src={imageFront || images[0]} alt={`${name} ${category}`} />
                      </div>
                      <div className='item__more-info-wrapper small-text'>
                        <h3 className='small-text font-thin'>
                          <div>
                            {namePartOne} {namePartTwo}
                          </div>
                        </h3>
                        <div className={priceClass}>
                          {makePriceString(locale, price, drate)}
                        </div>
                        {
                          sale ?
                              <span className='item__price--sale small-text'>
                                {makePriceString(locale, sale, drate)}
                              </span> : null
                        }
                      </div>
                    </NavLink>
                  )
                })
              }
            </div>
            {/*<div className='item__more-btn'>*/}
            {/*  <NavLink to='/stuff' className='button'>*/}
            {/*    {itemTextData[locale].moreStuff}*/}
            {/*  </NavLink>*/}
            {/*</div>*/}
          </div>
        }
      </div>
    )
  }

  // {
  //   sale ?
  //     <div className='items-list__sale-additional'>
  //       {makePriceString(locale, sale, drate)}
  //     </div> : null
  // }

  onInputChange = element => e => {
    this.setState({
      [element]: e.target.value
    })
  }
}

export default ItemPage
