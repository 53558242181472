import React from 'react'

function RNWBanner() {
  const banner = 'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/2024%2Fbanners%2Fpm-r-n-w-merch-mobile.png?alt=media&token=e6cdf3dd-0546-4f14-8d94-8a624f738c44';
  const bannerDesk = 'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/2024%2Fbanners%2Fpm-r-n-w-merch-desk.png?alt=media&token=dcac74ff-16e9-4513-933a-202d70d61255';
  const altText = 'RATT\'#\'WHORE';

  return (
    <div className='items-container__banner'>
        <div className='items-container__banner-image--portrait'>
          <img src={banner} width='2000' height='3000' alt={altText} />
        </div>
        <div className='items-container__banner-image--landscape'>
          <img src={bannerDesk} width='2000' height='2000' alt={altText} />
        </div>
    </div>
  )
}

export default RNWBanner
